import React from 'react';

export default function PartnerCompanies(props:any) {
  return (
    <div className="mb-5 companies">
        {props?.data?.map((item:any, index:number) => {
          return (
            <div className="partnercard" key={index}>
              <div>
                <img
                  src={item?.imgPath}
                  className="img-fluid"
                  alt="skill development courses"
                />
              </div>
            </div>
          );
        })}
      </div>
  );
}
