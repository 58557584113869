import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination } from 'swiper/modules';
import LocalImages from '../Constant/localImages';
export default function HomeBannerSlider(props:any) {
 
  return (
    <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination]}
        className="mySwiper"
      >
        {props?.data?.map((item:any,index:number)=>(
          <SwiperSlide key={index}>
            <div className='position-relative'>
                <img src={item?.imgPath} alt={item?.title} className='w-100 bannerImg'/>
                {/* <div className='bannerText'>
                    <div className='bannerHeading boldfont'>{item?.title}</div>
                    <div className='font18 mt-3'>{item?.subText}</div>
                    <div className='mt-4'>
                        <button className='btnOverView border-0'>AN OVERVIEW OF OUR JOURNEY</button>
                    </div>
                </div> */}
            </div>
        </SwiperSlide>
        ))}
      </Swiper>
  );
}
