import React, { useEffect, useRef, useState } from "react";
import "../Assets/CSS/invitation.css";
import LocalImages from "../Constant/localImages";
import ReactCrop, {
  type Crop,
  centerCrop,
  makeAspectCrop,
  PixelCrop,
  convertToPixelCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import UploadIcon from "@mui/icons-material/Upload";
import DownloadIcon from "@mui/icons-material/Download";
import { useDebounceEffect } from "../Hooks/useDebounceEffect";
import { canvasPreview } from "./canvasPreview";
import { imgPreview } from "./imgPreview";
import ImgCropPopup from "../Component/imgCropPopup";
import { useNavigate } from "react-router-dom";

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}
export default function Invitation() {
  const navigate = useNavigate();
  // const [selectedBtn,setSelectedBtn]=useState("Profile");
  const [sname, setName] = useState("");
  const [profileImg, setProfileImg] = useState<any>("");
  const [cropprofileImg, setCropProfileImg] = useState<any>("");
  const canvasRef = useRef(null);

  const [crop, setCrop] = useState<Crop>();
  console.log("crop", crop);
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [open, setopen] = useState(false);
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState<number | undefined>(4 / 5);
  const imgRef = useRef<HTMLImageElement>(null);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const hiddenFileInput: any = useRef(null);
  const handleClick = (event: any) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event: any) => {
    const reader = new FileReader();
    setCrop(undefined);
    const file = event.target.files[0];
    reader.onloadend = () => {
      setProfileImg(reader.result);
    };
    reader.readAsDataURL(file);
    setopen(true);
  };

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        imgPreview(
          imgRef.current,
          // previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
        console.log(
          "image",
          imgPreview(
            imgRef.current,
            // previewCanvasRef.current,
            completedCrop,
            scale,
            rotate
          ).then((res) => {
            setCropProfileImg(res);
          })
        );
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  const handleDownload = () => {
    const canvas: any = canvasRef.current;
    const link = document.createElement("a");
    link.download = "canvas-image.png";
    link.href = canvas.toDataURL();
    link.click();
  };
  useEffect(() => {
    drawCanvas();
  }, [sname, cropprofileImg]);

  const drawCanvas = () => {
    const canvas: any = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const img = new Image();

    // Load the main invitation card image
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);

      // Load the profile image
      if (cropprofileImg) {
        const profileImgElement = new Image();
        profileImgElement.onload = () => {
          ctx.drawImage(profileImgElement, 39, 534, 330, 404);

          // Draw text
          ctx.font = "bold 26px Poppins";
          ctx.textAlign = "center";
          ctx.fillStyle = "black";
          ctx.fillText(sname, 200, 1000); // Adjust the position as needed
        };
        profileImgElement.src = cropprofileImg; // Use profileImg as the source
      } else {
        // Draw text if no profile image is selected
        ctx.font = "bold 26px Poppins";
        ctx.textAlign = "center";
        ctx.fillStyle = "black";
        ctx.fillText(sname, 200, 1000); // Adjust the position as needed
      }
    };

    img.src = LocalImages?.invitationCard;
  };

  console.log("cropprofileImg", cropprofileImg);
  const gotoLink = () => {
    navigate("/");
  };
  return (
    <div className=" invitationCardBox">
      <div className="p-2 px-5">
        <img
          src={LocalImages?.logo}
          alt="Logo"
          className="headerLogo cursor"
          onClick={gotoLink}
        />
      </div>
      <div className="container d-flex h-100">
        <div className="invitationCard">
          <h3 className="fw-bold text-center mb-3">अपना आमंत्रण कार्ड बनाएं</h3>
          {/* <div className='d-flex mb-2 text-center justify-content-center'>
        <button type="button" className={`btn me-3  ${selectedBtn==="Profile"?'btn-primary':'btn-secondary'}`} onClick={()=>setSelectedBtn("Profile")}>Profile</button>
        <button type="button" className={`btn  ${selectedBtn==="Banner"?'btn-primary':'btn-secondary'}`} onClick={()=>setSelectedBtn("Banner")}>Banner</button>
        </div> */}
          <div className="row mx-0">
            <div className="col-md-6 px-0 px-sm-2">
              <div className="position-relative" id="canvasDiv">
                <img
                  src={LocalImages?.invitationCard}
                  alt="cardImg"
                  className="invitationCardImg"
                />
                <div className="profileText fw-bold">{sname}</div>
                {cropprofileImg ? (
                  <img
                    src={cropprofileImg}
                    alt="cardImg"
                    className="invitationCardProfileImg"
                  />
                ) : null}
              </div>
              <div>
                <canvas ref={canvasRef} style={{ display: "none" }}></canvas>
              </div>
            </div>
            <div className="col-md-5">
              <div className="my-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label fw-bold"
                >
                  अपना नाम लिखें
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleFormControlInput1"
                  placeholder="Enter Your Name"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div>
                <button
                  type="button"
                  className="btn btn-success w-100"
                  onClick={handleClick}
                >
                  <UploadIcon /> अपनी फोटो अपलोड करें
                </button>
                <input
                  type="file"
                  onChange={handleChange}
                  ref={hiddenFileInput}
                  style={{ display: "none" }} // Make the file input element invisible
                />
              </div>
              <div className="mt-3 text-center">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={handleDownload}
                >
                  <DownloadIcon /> डाउनलोड और शेयर करें
                </button>
              </div>
              {/* <div>
                <ReactCrop crop={crop} onChange={(_, percentCrop) => setCrop(percentCrop)} minHeight={100} onComplete={(c) => setCompletedCrop(c)} locked={true}>
                  {profileImg ? <img src={profileImg} alt='cardImg' className='' style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                    onLoad={onImageLoad} ref={imgRef} /> : null}
                </ReactCrop>
              </div> */}
              {!!completedCrop && (
                <div>
                  <canvas
                    ref={previewCanvasRef}
                    style={{
                      border: "1px solid black",
                      objectFit: "contain",
                      width: completedCrop.width,
                      height: completedCrop.height,
                      display: "none",
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <ImgCropPopup
        open={open}
        handleClose={() => setopen(false)}
        crop={crop}
        setCrop={setCrop}
        setCompletedCrop={setCompletedCrop}
        profileImg={profileImg}
        scale={scale}
        rotate={rotate}
        onImageLoad={onImageLoad}
        imgRef={imgRef}
      />
    </div>
  );
}
