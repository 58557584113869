

export default function AowCard(props:any) {
  return (
      <div className='position-relative aowCard'>
          <img src={props?.data?.bannerImg} alt={props?.data?.Title1} className='aowImg' />
          <div className='d-flex position-absolute bottom-0 w-100 aowContent1'>
              {/* <div className={`aowiconBg ${props?.count%2?"pink":'yellow'}`}> */}
              <div className={`aowiconBg pink`}>
                  <img src={props?.data?.icon} alt='aow 1' className='aowIcon' />
              </div>
              <div className='aowTextBg'>
                  {props?.data?.Title1}
              </div>
          </div>
          <div className='aowContent2'>
              <div className='fw-bold aowContent2Heading mb-4 mb-md-5 boldfont'> {props?.data?.Title1}</div>
              <div className='aowContent2MiddleContainer text-center'>
                  <div className='row'>
                    {/* {props?.data?.subcontent?.map((item:any,index:number)=>(
                        <div className={`col-6 p-4 ${index===0?'border-end border-bottom':index===1?'border-bottom':index===2?"border-end":""} `}>
                        <div className='text-center font24 boldfont'>{item?.count}</div>
                        <div className='text-center font20 fw-bold'>{item?.text}</div>
                    </div>
                    ))} */}
                     <div className='text-center font20 boldfont'>{props?.data?.subcontent}</div>
                  </div>
                  <button className='aowBtn btn btn-dark font16 rounded mt-4 mt-md-5'>Read More</button>
              </div>
          </div>
      </div>
  );
}
