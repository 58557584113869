// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bgLightGrey{
    background-color: #FAFAFA;
  }
  .footer{
    background-color: #4E4A4A; 
    color: #FFFFFF; 

}

 .footerInputs{
    background: #4E4A4A;
    border-left: none;
    border-right: none;
    border-top: none;
 }
 .footerInputs:focus-visible{
    outline: 0;
 }

 .footerLogo{
   width: 150px;
 }`, "",{"version":3,"sources":["webpack://./src/Assets/CSS/footer.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;EAC3B;EACA;IACE,yBAAyB;IACzB,cAAc;;AAElB;;CAEC;IACG,mBAAmB;IACnB,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;CACnB;CACA;IACG,UAAU;CACb;;CAEA;GACE,YAAY;CACd","sourcesContent":[".bgLightGrey{\n    background-color: #FAFAFA;\n  }\n  .footer{\n    background-color: #4E4A4A; \n    color: #FFFFFF; \n\n}\n\n .footerInputs{\n    background: #4E4A4A;\n    border-left: none;\n    border-right: none;\n    border-top: none;\n }\n .footerInputs:focus-visible{\n    outline: 0;\n }\n\n .footerLogo{\n   width: 150px;\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
