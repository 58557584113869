import React from "react";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { useAppDispatch, useAppSelector } from "../hooks";
import { SnackbarReducerModal } from "../Modals";

function SuccessSnackbar() {
  const dispatch = useAppDispatch();
  const { successSnackbarOpen, message } = useAppSelector(
    (state: { uiReducer: SnackbarReducerModal }) => ({
      successSnackbarOpen: state.uiReducer.successSnackbarOpen,
      message: state.uiReducer.message,
    })
  );

  function handleClose() {
    dispatch({ type: "SNACKBAR_CLEAR" });
  }
  return (
    <Snackbar
      open={successSnackbarOpen}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        onClose={handleClose}
        severity="success"
        sx={{ width: "100%" }}
      >
        {message}
      </MuiAlert>
    </Snackbar>
  );
}

export default SuccessSnackbar;
