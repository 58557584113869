import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination } from 'swiper/modules';
import LocalImages from '../Constant/localImages';
import YTThumbnail from './ytThumbnailGirlBook';
export default function OurVoiceSwiper() {
  return (
    <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 82500,
          disableOnInteraction: false,
        }}
        // pagination={{
        //   clickable: true,
        // }}
        navigation={true}
        modules={[Autoplay, Pagination]}
        className="mySwiper"
      >
      <SwiperSlide>
        <div className='position-relative ourVoiceSwiper'>
          <div className='row'>
            <div className='col-md-8'>
              <video className='ourVoiceVideo' controls>
                <source src={LocalImages?.ourVoiceVideo} type="video/mp4" />
              </video>
            </div>
            <div className='col-md-4 d-flex align-items-center'>
              <div className='ourVoiceText'>
                <div><img src={LocalImages?.doubleQoutes} alt='Qoutes' className='qoutes'/></div>
                <div className='font16 mt-3 text'>Rashmii is a beacon of hope, making primary healthcare accessible and empowering girls through education and skill training. Their motto, 'Educate a Girl, Empower a Nation,' resonates deeply. Join us in supporting Rashmii's mission to create a better, more equitable world. Together, we can uplift communities and change lives.</div>
              <div className='mt-4 font18 fw-bold'>Romila Tutoo [COO, Dhister Pvt Ltd]</div>
              </div>
          </div>
          </div>          
        </div>
      </SwiperSlide>
    
      </Swiper>
  );
}
