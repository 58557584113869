import React, { useEffect, useState } from "react";
import Header from "../Component/header";
import HomeBannerSlider from "../Component/homeBannerSlider";
import "../Assets/CSS/volunteer.css";
import "../Assets/CSS/home.css";
import LocalImages from "../Constant/localImages";
import AowCard from "../Component/aowCard";
import PartnerCompanies from "../Component/partnerCompanies";
import OurVoiceSwiper from "../Component/ourVoiceSwiper";
import ContactPopUP from "../Component/contactPopup";
import Footer from "../Component/footer";
import GetInvoled from "../Component/getInvoled";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination } from "swiper/modules";
import VolunteerCard from "../Component/volunteerCard";
import ContactCard from "../Component/contactCard";
import VolunteerActivity from "../Component/volunteerActivity";
import VoulnteerSwiper from "../Component/volunteerSwiper";
import TestimonialCard from "../Component/testimonialCard";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import { containsNumber, validateValueWithoutSpace } from "../utils/validators";
import { showErrorSnackbar } from "../Redux/Actions/snackbarAction";
import { validateIsNumberOnlyErrMsg } from "../utils/errorMsg";
import { addContactUs } from "../Redux/Actions/AuthAction";
import { useAppDispatch } from "../hooks";
import DropForm from "./dropFrom";

export default function Volunteer() {
  const [open, setOpen] = useState<boolean>(false);
  const [isHidden, setisHidden] = useState<boolean>(true);

  const handleClose = () => {
    setOpen(false);
  };


  const volunteerData = [
    {
      bannerImg: LocalImages?.digitallab,
      Title1: "Making Digital Learning Accessible to All.",
      boxTitle: "Digital Lab",
      // icon: LocalImages?.nanhiKaliIcon,
      // Title2: "IMPACT",
      // subcontent: "Making Primary Healthcare Accessible for All Girls",
    },
    {
      bannerImg: LocalImages?.education,
      Title1: "Educate a Girl, Empower a Nation.",
      boxTitle: "Education",
      // icon: LocalImages?.ladderIcon,
      // Title2: "IMPACT",
      // subcontent: "Educate a Girl, Empower a Nation",
    },
    {
      bannerImg: LocalImages?.meal,
      Title1: "Feeding the Future: Meals for Needy Children by Rashmii.",
      boxTitle: "Food",
      // icon: LocalImages?.educationIcon,
      // Title2: "IMPACT",
      // subcontent: "A Girl with a Book is a Vaccine Against Poverty",
    },
    {
      bannerImg: LocalImages?.health,
      Title1: "Making Primary Healthcare Accessible for All Girls.",
      boxTitle: "Help",
      // icon: LocalImages?.tayariKalKi,
      // Title2: "IMPACT",
      // subcontent: "Enabling Livelihoods for Girls through Skill Training",
    },
  ];

  const testimonialImages = [
    {
      testImage: LocalImages.slide1,
      name: "Julie Mansuy",
    },
    {
      testImage: LocalImages.slide2,
      name: "John Singhal",
    },
    {
      testImage: LocalImages.slide3,
      name: "Annie Joe",
    },
    {
      testImage: LocalImages.slide4,
      name: "Bob Besant",
    },
  ];

  var myScrollFunc = function () {
    // let offsetHeight = stageCanvasRef.current.offsetHeight;
    if (window.innerWidth >= 768) {
      var y = window.scrollY;
      if (y >= 90) {
        setisHidden(false);
      } else {
        setisHidden(true);
      }
    } else {
      setisHidden(false);
    }
  };
  window.addEventListener("scroll", myScrollFunc);

  const recentActivityData = [
    {
      bannerImg: LocalImages?.career,
      Title1: "CAREER",
      Title2: "CAREER",
      subcontent: "Divya Sonwane honored as Rashmii's 2024 Woman of Professional.",
    },
    {
      bannerImg: LocalImages?.food,
      Title1: "FOOD",
      Title2: "FOOD",
      subcontent: "Feeding the Future: Meals for Needy Children by Rashmii.",
    },
    {
      bannerImg: LocalImages?.hostel,
      Title1: "HOSTEL",
      Title2: "HOSTEL",
      subcontent: "Rashmii unveils a new hostel for girls in Pune.",
    },
    {
      bannerImg: LocalImages?.labopen,
      Title1: "LAB OPENING",
      Title2: "LAB OPENING",
      subcontent: "Making Digital Learning Accessible to All.",
    },
  ];

  const bannerData = [
    {
      imgPath: LocalImages?.contactUsBannerImg1,
      title: "Nanhi Kali",
      subText: "Making Primary Healthcare Accessible for All Girls",
    },
    {
      imgPath: LocalImages?.contactUsBannerImg2,
      title: "She Can Fly",
      subText: "Educate a Girl, Empower a Nation",
    },
    {
      imgPath: LocalImages?.contactUsBannerImg3,
      title: "Shiksha Na Ruke",
      subText: "A Girl with a Book is a Vaccine Against Poverty",
    },
    {
      imgPath: LocalImages?.contactUsBannerImg4,
      title: "Tayyari kal Ki",
      subText: "Enabling Livelihoods for Girls Through Skill Training",
    },
  ];

  const speech = [
    {
      slideText: "Romila Tutoo, Chief Marketing Officer (CMO) at Rashmi Welfare Society, drives the NGO’s outreach and engagement efforts with her strategic communications expertise. Committed to social change, Romila's innovative marketing approach amplifies young women’s voices and supports impactful programs. Her leadership advances gender equality and provides crucial opportunities for girls to thrive, making a significant difference in their lives."
    },
    {
      slideText: "Digambar Jagtap, Chief Operating Officer (COO) at Rashmi Welfare Society, excels in driving efficiency and results. His commitment to optimizing processes and ensuring smooth operations is key to the NGO’s growth and success. Under his leadership, the society has strengthened its impact and fulfilled its mission with dedication. Digambar’s strategic vision and operational skills continue to advance the organization’s goals and create lasting positive change."
    },
    {
      slideText: "Dadasaheb Sondge, Chief Executive Officer (CEO) of Rashmi Welfare Society, leads the NGO with a focus on advancing girls' education and empowerment. His strategic vision and leadership drive the organization’s mission, ensuring impactful educational programs and initiatives. Dadasaheb’s commitment enhances the NGO’s effectiveness, significantly improving the lives of young girls and expanding its reach and impact."
    },
  ];


  const email = [
    {
      emailId: "volunteer@rashmii.org",
    }
  ];

  const goToDiv = (val: string) => {
    const element = document.getElementById(val);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start",
      });
    }
  };

  return (
    <div>
      <Header />
      <div className="homeBanner contactPage">
        <HomeBannerSlider data={bannerData} />
      </div>

      <div className="aow commonPad mt-3 mt-xl-5">
        <div className="row">
          <div className="col-md-12 col-xl-6 my-3 mt-xl-4  d-flex justify-content-center justify-content-xl-start">

            <img src={LocalImages?.volunteer} alt="img1" className="bgImage" />

          </div>
          <div className="col-md-12 col-xl-6">
            <div className="font48 fw-bold mt-3 mt-xl-4 text-center text-xl-start">
              <div className="headingborder">
                Volunteers
              </div>
            </div>
            <div className="d-flex flex-column justify-content-between flex-fill">
              <div>
                {/* <div className="font18 pb-2 pt-4 mt-3 fw-bold">
                 The Most frequently searched and most popular apartment of the application will be in the list
                </div> */}
                <div className="mt-3 font16 text-secondary">
                  Volunteer with Rashmii and be a part of our Transforming Lives, Empowering Futures of India community, where you would get to meet the spirited 10-year-old Divya to the wise and wonderful 70-year-old Vipul!
                  We welcome all committed people, young or old, to come and volunteer for the Empowering Girls, Transforming India. In normal times or in times of disaster, volunteers play an active role. Professionals, students, the retired, or the serving, help in carrying out our Programs or Advocacy work.
                </div>
              </div>
              <div className="mt-2 mt-xl-5 text-center text-xl-start">
                <button className='pinkBtn font16 py-2 px-5 rounded fw-bold my-4' onClick={() => goToDiv('VolunteerForm')}>Become A Volunteer</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="aow commonPad mt-3 mt-xl-5">
        <div className="text-center">
          <div className="font48 fw-bold mb-2">Volunteering Opportunities</div>
          <div className="font16 Subheadingborder d-inline-block pb-2 text-secondary">
            Transforming Lives, Empowering Futures
          </div>
        </div>
        <div className="mt-4">
          <div className="row">
            {volunteerData?.map((item, index) => (
              <div className="col-md-6 col-xl-3 mb-3 mt-3 mt-md-5">
                <div className="d-flex justify-content-center">
                  <VolunteerCard data={item} count={index} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="aow commonPad bgcolor mt-3 mt-xl-5">
        <div className="text-center">
          <div className="font48 fw-bold my-2">Recent Activities</div>
          <div className="font16 text-secondary px-3 pb-2">
            <div className="Subheadingborder d-inline-block ">
              Together We Can, Together We Will - Bringing Dreams to Life
            </div>
          </div>
        </div>
        <div className="mt-4">
          <div className="row">
            {recentActivityData?.map((item, index) => (
              <div className="col-md-6 col-xl-3 mb-3 mt-3 mt-md-5">
                <div className="d-flex justify-content-center">
                  <VolunteerActivity data={item} count={index} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="mt-3 mt-xl-5">
        {email?.map((item, index) => (
          <div id="VolunteerForm">
            <DropForm
              data={item}
            />
          </div>
        ))}
      </div>

      <div className="aow commonPad mt-3 mt-xl-5">
        <div className="text-center">
          <div className="font48 fw-bold mb-2">Help Us Improve Our Productivity</div>
          <div className="font16 text-secondary Subheadingborder d-inline-block pb-2">
            Voice of Change, Heart of Strength
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-12 col-xl-6 mt-2 mt-xl-4 d-flex justify-content-center justify-content-xl-start">
            <div>
              <img src={LocalImages?.productive} alt="img1" className="sideImage" />
            </div>

          </div>
          <div className="col-md-12 col-xl-6">
           
            <div>

              <VoulnteerSwiper/>
       
            </div>
          </div>
        </div>
      </div>


      <GetInvoled openPopup={() => setOpen(true)} />
      <ContactPopUP open={open} handleClose={handleClose} />
      <Footer />
      <div className="donateFixed d-flex mt-5">
        <img
          src={LocalImages?.donateIcon}
          alt="donate"
          className="donateImg me-3"
        />
        <div className="font18">Donate</div>
      </div>
    </div >
  );
}
