export default function VolunteerCard(props: any) {
    return (
        <div className='position-relative vcards h-100'>
            <div className="row">
                <div className="col-sm-12">
                    <img src={props?.data?.bannerImg} alt={props?.data?.Title1} className='imageblock' />
                </div>
            </div>
            <div className="row position-relative">
                <div className="boxtext">
                    {props?.data?.boxTitle}
                </div>
                <div className='col-sm-12 imagetext px-5 pt-5 py-3'>
                    {props?.data?.Title1}
                </div>

                <div className='col-sm-12  d-flex justify-content-center'>
                    <button className='pinkBtn font16 py-2 px-5 rounded fw-bold my-4'>Read More</button>
                </div>
            </div>

        </div>
    );
}