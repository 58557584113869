import { useNavigate } from "react-router-dom";
import "../Assets/CSS/partnerSupport.css"


export default function PartnerCard(props: any) {
    console.log(props);

    // const navigate = useNavigate();
    // const gotoLink = (val: string) => {
    //     navigate("/" + val)
    // }

    // const getLink = (href:any) => {
    //     console.log("href",href)
    //     return(
    //       <a href={href} ></a>
    //     )
    //   }


    return (
        <div className="d-flex justify-content-between align-items-center my-4">

            <a className="col-md-2 cursor" href={props?.data?.partnerWebsite} target="_blank" rel="noopener noreferrer">
                <img src={props?.data?.partnerLogo} alt={props?.data?.partnerName} className="logo p-3" />
            </a>
            <div className="ourpartner col-md-10">
                <div className="my-2 fw-bold fs-6">
                    {props?.data?.partnerName}
                </div>
                <div className="my-2">
                    {props?.data?.partnerText}
                </div>
                <div className="mt-3">
                <a className="pinkBtn py-2 px-2 knowmore rounded text-decoration-none" href={props?.data?.partnerWebsite} role="button">
                    Know More
                </a>
                </div>
            </div>

        </div >
    )
}