import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import "../Assets/CSS/pop.css";
import CallIcon from "@mui/icons-material/Call";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination } from "swiper/modules";
import LocalImages from "../Constant/localImages";
import { containsNumber, validateValueWithoutSpace } from "../utils/validators";
import { showErrorSnackbar } from "../Redux/Actions/snackbarAction";
import { useAppDispatch } from "../hooks";
import { validateIsNumberOnlyErrMsg } from "../utils/errorMsg";
import { addUser } from "../Redux/Actions/AuthAction";
interface props {
  open: boolean;
  handleClose: Function;
}
export default function ContactPopUP({ open, handleClose }: props) {
  function closePopup(
    event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ): void {
    handleClose();
  }
  const dispatch = useAppDispatch();
  const [username, setUsername] = useState("");

  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const bannerData = [
    {
      imgPath: LocalImages?.homeBannerImg1,
      title: "Nanhi Kali",
      subText: "Making Primary Healthcare Accessible for All Girls",
    },
    {
      imgPath: LocalImages?.homeBannerImg2,
      title: "She Can Fly",
      subText: "Educate a Girl, Empower a Nation",
    },
    {
      imgPath: LocalImages?.homeBannerImg3,
      title: "Shiksha Na Ruke",
      subText: "A Girl with a Book is a Vaccine Against Poverty",
    },
    {
      imgPath: LocalImages?.homeBannerImg4,
      title: "Tayyari kal Ki",
      subText: "Enabling Livelihoods for Girls Through Skill Training",
    },
  ];

  const onChangeNumber = (e: any) => {
    if (e.target.value !== "") {
      if (!containsNumber(e.target.value)) {
        dispatch(showErrorSnackbar("Only Numbers are allowed!"));
        return;
      } else if (!validateValueWithoutSpace(e.target.value)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg));
        return;
      } else {
        setNumber(e.target.value);
      }
    } else {
      setNumber(e.target.value);
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const postdata = {
      name: username,
      email: email,
      mobileNo: number,
      type: 1,
    };
    console.log("postdata", postdata);
    dispatch(
      addUser(
        postdata,
        () => {
          setUsername("");
          setEmail("");
          setNumber("");
          handleClose();
        },
        () => {}
      )
    );
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="model"
      open={open}
      onClose={closePopup}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className="p-0 text-center position-relative popup">
          <div className="containerPopUp d-flex justify-content-between p-5">
            <div className="col-md-6">
              <div className="heading">Get In Touch</div>
              <p>We are here for you. How can we help you.? </p>
              <form onSubmit={handleSubmit} className="form">
                <input
                  className="input mb-2"
                  type="text"
                  name="username"
                  id="username"
                  value={username}
                  placeholder="Full Name"
                  onChange={(e) => {
                    setUsername(e.target.value);
                  }}
                />
                <input
                  className="input mb-2"
                  type="email"
                  name="email"
                  id="email"
                  value={email}
                  placeholder="Email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
                <input
                  className="input mb-2"
                  type="text"
                  name="number"
                  id="number"
                  value={number}
                  placeholder="Contact Number"
                  onChange={(e) => {
                    onChangeNumber(e);
                  }}
                />

                <input
                  className="login-button mt-5"
                  type="submit"
                  value="Sign In"
                />
              </form>
            </div>
            <div className="col-md-6 p-2">
              <div className="p-5">
                <Swiper
                  spaceBetween={30}
                  centeredSlides={true}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  modules={[Autoplay, Pagination]}
                  className="mySwiper swiperRadius"
                >
                  {bannerData?.map((item, index) => (
                    <SwiperSlide key={index}>
                      <div className="position-relative">
                        <img
                          src={item?.imgPath}
                          alt={item?.title}
                          className="w-100  rounded-3 "
                        />
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
              <div className="d-flex justify-content-start px-5 py-2">
                <div className="  icons me-3  rounded-circle">
                  <LocationOnIcon className="iconColor" />
                </div>
                <div className="col-md-10 d-flex text-start">
                  B - 7 , Saraswati Nagar, Nr. St. Anne's School , Basni - 1
                </div>
              </div>
              <div className="d-flex justify-content-start px-5 py-2">
                <div className="  icons   me-3 rounded-circle">
                  <CallIcon className="iconColor" />
                </div>
                <div className="col-md-10 d-flex align-items-center">
                  8860695011
                </div>
              </div>
              <div className="d-flex justify-content-start px-5 py-2">
                <div className="icons rounded-circle me-3">
                  <EmailIcon className="iconColor" />
                </div>
                <div className="col-md-10 d-flex align-items-center">
                  {" "}
                  contact@rashamii.org
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
