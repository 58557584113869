import React, { useState } from "react";
import Header from "../Component/header";
import HomeBannerSlider from "../Component/homeBannerSlider";
import "../Assets/CSS/home.css";
import "../Assets/CSS/contact.css";
import LocalImages from "../Constant/localImages";
import AowCard from "../Component/aowCard";
import PartnerCompanies from "../Component/partnerCompanies";
import OurVoiceSwiper from "../Component/ourVoiceSwiper";
import ContactPopUP from "../Component/contactPopup";
import Footer from "../Component/footer";
import GetInvoled from "../Component/getInvoled";
// import Swiper from 'swiper';
import { SwiperClass, SwiperSlide, Swiper } from "swiper/react";
import CallIcon from "@mui/icons-material/Call";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination } from "swiper/modules";
import ContactCard from "../Component/contactCard";
import { addContactUs } from "../Redux/Actions/AuthAction";
import { useAppDispatch } from "../hooks";
import { containsNumber, validateValueWithoutSpace } from "../utils/validators";
import { showErrorSnackbar } from "../Redux/Actions/snackbarAction";
import { validateIsNumberOnlyErrMsg } from "../utils/errorMsg";
import DropForm from "./dropFrom";
export default function Contact() {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const [isHidden, setisHidden] = useState<boolean>(true);
  const handleClose = () => {
    setOpen(false);
  };
  const areaOfWorkData1 = [
    {
      bannerImg: LocalImages?.aowImg1,
      Title1: "Nanhi Kali",
      icon: LocalImages?.nanhiKaliIcon,
      Title2: "IMPACT",
      subcontent: "Making Primary Healthcare Accessible for All Girls",
    },
    {
      bannerImg: LocalImages?.aowImg2,
      Title1: "Udaan",
      icon: LocalImages?.ladderIcon,
      Title2: "IMPACT",
      subcontent: "Educate a Girl, Empower a Nation",
    },
    {
      bannerImg: LocalImages?.aowImg3,
      Title1: "Shiksha Na Ruke",
      icon: LocalImages?.educationIcon,
      Title2: "IMPACT",
      subcontent: "A Girl with a Book is a Vaccine Against Poverty",
    },
    {
      bannerImg: LocalImages?.aowImg4,
      Title1: "Tayyari Kal Ki",
      icon: LocalImages?.tayariKalKi,
      Title2: "IMPACT",
      subcontent: "Enabling Livelihoods for Girls through Skill Training",
    },
  ];

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [message, setMessage] = useState("");
  const handleSubmit = (e: any) => {
    e.preventDefault();
    const postdata = {
      name: username,
      email: email,
      mobileNo: number,
      message: message,
    };
    dispatch(
      addContactUs(
        postdata,
        () => {
          setUsername("");
          setEmail("");
          setNumber("");
          setMessage("");
        },
        () => { }
      )
    );
  };
  const bannerData = [
    {
      imgPath: LocalImages?.contactUsBannerImg1,
      title: "Nanhi Kali",
      subText: "Making Primary Healthcare Accessible for All Girls",
    },
    {
      imgPath: LocalImages?.contactUsBannerImg2,
      title: "She Can Fly",
      subText: "Educate a Girl, Empower a Nation",
    },
    {
      imgPath: LocalImages?.contactUsBannerImg3,
      title: "Shiksha Na Ruke",
      subText: "A Girl with a Book is a Vaccine Against Poverty",
    },
    {
      imgPath: LocalImages?.contactUsBannerImg4,
      title: "Tayyari kal Ki",
      subText: "Enabling Livelihoods for Girls Through Skill Training",
    },
  ];

  const mail = [

    {
      emailId: "contact@rashmi.org",
    }
  ];

  var myScrollFunc = function () {
    // let offsetHeight = stageCanvasRef.current.offsetHeight;
    if (window.innerWidth >= 768) {
      var y = window.scrollY;
      if (y >= 90) {
        setisHidden(false);
      } else {
        setisHidden(true);
      }
    } else {
      setisHidden(false);
    }
  };
  window.addEventListener("scroll", myScrollFunc);

  const onChangeNumber = (e: any) => {
    if (e.target.value !== "") {
      if (!containsNumber(e.target.value)) {
        dispatch(showErrorSnackbar("Only Numbers are allowed!"));
        return;
      } else if (!validateValueWithoutSpace(e.target.value)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg));
        return;
      } else {
        setNumber(e.target.value);
      }
    } else {
      setNumber(e.target.value);
    }
  };
  return (
    <div>
      <Header />
      <div className="homeBanner contactPage mb-5">
        <HomeBannerSlider data={bannerData} />
      </div>

      <div>
        {mail?.map((item: any, index: any) => (
          <div className="mt-4">
            <DropForm
              data={item}
            />
          </div>
        ))}
      </div>
      
      {/* <div className=" containerSignin text-center">
        <div>
          <div className="font48 fw-bold mb-2">Get In Touch</div>
          <p className="font18 text-secondary d-inline-block pb-2">
            We are here for you. How can we help you.?{" "}
          </p>
        </div>
        <div className="d-flex justify-content-between row">
          <div className="col-md-5">
            <form onSubmit={handleSubmit} className="inputform">
              <input
                className="input mb-2"
                type="text"
                name="username"
                id="username"
                value={username}
                placeholder="Full Name"
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
              />
              <input
                className="input mb-2"
                type="email"
                name="email"
                id="email"
                value={email}
                placeholder="Email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <input
                className="input mb-2"
                type="text"
                name="number"
                id="number"
                maxLength={10}
                value={number}
                placeholder="Contact Number"
                onChange={(e) => {
                  onChangeNumber(e);
                }}
              />

              <textarea
                className="input mb-2"
                cols={5}
                rows={10}
                name="message"
                id="message"
                value={message}
                placeholder="Message"
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
              />

              <input
                className="login-button mt-4"
                type="submit"
                value="Send a Message"
              />
            </form>
          </div>
          <div className="col-md-7">
            <div className="p-4">
              <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                modules={[Autoplay, Pagination]}
                className="mySwiper swiperRadius"
              >
                {formCurouserData?.map((item, index) => (
                  <SwiperSlide key={index}>
                    <div className="position-relative">
                      <img
                        src={item?.imgPath}
                        alt={item?.title}
                        className="w-100 rounded-3 "
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            <div className="row ps-3">
              <div className="col-md-6 mt-3 mt-md-0">
                <div className="d-flex justify-content-start">
                  <div className="col-3 col-md-2 p-3">
                    <img
                      src={LocalImages?.puneofc}
                      className="img-fluid"
                      alt="Icon"
                    />
                  </div>
                  <div className="col-md-9 ps-2 text-start">
                    <p className="fw-bold mb-0">Pune</p>
                    <p className="address">
                      9, Skyii Aquila Co-Operative Society, Baner, Pune
                    </p>
                  </div>
                </div>

                <div className="d-flex justify-content-start">
                  <div className="col-3 col-md-2 p-3">
                    <img
                      src={LocalImages?.jodhpurofc}
                      className="img-fluid"
                      alt="Icon"
                    />
                  </div>
                  <div className="col-md-9 ps-2 text-start">
                    <p className="fw-bold mb-0">Jodhpur</p>
                    <p className="address">
                      B - 7 , Saraswati Nagar, Nr. St. Anne's School , Basni - 1
                    </p>
                  </div>
                </div>

                <div className="d-flex justify-content-start">
                  <div className="col-3 col-md-2 p-3">
                    <img
                      src={LocalImages?.calforniaofc}
                      className="img-fluid"
                      alt="Icon"
                    />
                  </div>
                  <div className="col-md-9 ps-2 text-start">
                    <p className="fw-bold mb-0">California</p>
                    <p className="address">
                      1525 McCarthy Blvd, 1039,Milpitas, CA - 95035
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex justify-content-start px-3 py-2">
                  <div className="  icons   me-3 rounded-circle">
                    <CallIcon className="iconColor" />
                  </div>
                  <div className="col-md-10 d-flex align-items-center">
                    +91-8949744910
                  </div>
                </div>
                <div className="d-flex justify-content-start px-3 py-2">
                  <div className="icons rounded-circle me-3">
                    <EmailIcon className="iconColor" />
                  </div>
                  <div className="col-md-10 d-flex align-items-center">
                    {" "}
                    contact@rashmii.org
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="aow commonPad">
        <div className="text-center">
          <div className="font48 fw-bold mb-2">Support A Girl</div>
          <div className="font18 text-secondary Subheadingborder d-inline-block pb-2">
            Bringing Smiles to Girls Through Education & Empowerment
          </div>
        </div>
        <div className="mt-4">
          <div className="row">
            {areaOfWorkData1?.map((item, index) => (
              <div className=" col-md-6 col-lg-6 col-xl-3 mb-3">
                <ContactCard data={item} count={index} />
              </div>
            ))}
          </div>
        </div>
      </div>

      <ContactPopUP open={open} handleClose={handleClose} />

      <GetInvoled openPopup={() => setOpen(true)} />
      <Footer />
      <div className="donateFixed d-flex">
        <img
          src={LocalImages?.donateIcon}
          alt="donate"
          className="donateImg me-3"
        />
        <div className="font18">Donate</div>
      </div>
    </div>
  );
}
