import React, { useState } from "react";
import { useAppDispatch } from "../hooks";
import { addContactUs } from "../Redux/Actions/AuthAction";
import { containsNumber, validateValueWithoutSpace } from "../utils/validators";
import { showErrorSnackbar } from "../Redux/Actions/snackbarAction";
import { validateIsNumberOnlyErrMsg } from "../utils/errorMsg";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import LocalImages from "../Constant/localImages";
import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

function DropForm(props:any) {
    console.log("props",props)
const dispatch = useAppDispatch();
const [username, setUsername] = useState("");
const [email, setEmail] = useState("");
const [number, setNumber] = useState("");
const [message, setMessage] = useState("");
const handleSubmit = (e: any) => {
    e.preventDefault();
    const postdata = {
        name: username,
        email: email,
        mobileNo: number,
        message: message,
    };
    dispatch(
        addContactUs(
            postdata,
            () => {
                setUsername("");
                setEmail("");
                setNumber("");
                setMessage("");
            },
            () => { }
        )
    );
};

const onChangeNumber = (e: any) => {
    if (e.target.value !== "") {
        if (!containsNumber(e.target.value)) {
            dispatch(showErrorSnackbar("Only Numbers are allowed!"));
            return;
        } else if (!validateValueWithoutSpace(e.target.value)) {
            dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg));
            return;
        } else {
            setNumber(e.target.value);
        }
    } else {
        setNumber(e.target.value);
    }
};

const formCurouserData = [
    {
        imgPath: LocalImages?.GetInTouch1,
        title: "Nanhi Kali",
    },
    {
        imgPath: LocalImages?.GetInTouch2,
        title: "She Can Fly",
    },
];

return(

    <div className="containerSignin text-center">
        <div>
            <div className="font48 fw-bold mb-2">Become A Volunteer</div>
            <p className="font18 text-secondary d-inline-block pb-2">
                We are here for you. How can we help you.?{" "}
            </p>
        </div>
        <div className="d-flex justify-content-between row">
            <div className="col-md-5">
                <form onSubmit={handleSubmit} className="inputform">
                    <input
                        className="input mb-2"
                        type="text"
                        name="username"
                        id="username"
                        value={username}
                        placeholder="Full Name"
                        onChange={(e) => {
                            setUsername(e.target.value);
                        }}
                    />
                    <input
                        className="input mb-2"
                        type="email"
                        name="email"
                        id="email"
                        value={email}
                        placeholder="Email"
                        onChange={(e) => {
                            setEmail(e.target.value);
                        }}
                    />
                    <input
                        className="input mb-2"
                        type="text"
                        name="number"
                        id="number"
                        maxLength={10}
                        value={number}
                        placeholder="Contact Number"
                        onChange={(e) => {
                            onChangeNumber(e);
                        }}
                    />

                    <textarea
                        className="input mb-2"
                        cols={5}
                        rows={10}
                        name="message"
                        id="message"
                        value={message}
                        placeholder="Message"
                        onChange={(e) => {
                            setMessage(e.target.value);
                        }}
                    />

                    <input
                        className="login-button mt-4"
                        type="submit"
                        value="Send a Message"
                    />
                </form>
            </div>
            <div className="col-md-7">
                <div className="p-4">
                    <Swiper
                        spaceBetween={30}
                        centeredSlides={true}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        pagination={{
                            clickable: true,
                        }}
                        modules={[Autoplay, Pagination]}
                        className="mySwiper swiperRadius"
                    >
                        {formCurouserData?.map((item, index) => (
                            <SwiperSlide key={index}>
                                <div className="position-relative">
                                    <img
                                        src={item?.imgPath}
                                        alt={item?.title}
                                        className="w-100 rounded-3 "
                                    />
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
                <div className="row ps-3">
                    <div className="col-md-6 mt-3 mt-md-0">
                        <div className="d-flex justify-content-start align-items-center">
                            <div className="col-3 col-md-2 p-3">
                                <img
                                    src={LocalImages?.puneofc}
                                    className="img-fluid"
                                    alt="Icon"
                                />
                            </div>
                            <div className="col-md-9 ps-2 text-start">
                                <p className="fw-bold mb-0">Pune</p>
                                <p className="address">
                                    9, Skyii Aquila Co-Operative Society, Baner, Pune
                                </p>
                            </div>
                        </div>

                        <div className="d-flex justify-content-start align-items-center">
                            <div className="col-3 col-md-2 p-3">
                                <img
                                    src={LocalImages?.jodhpurofc}
                                    className="img-fluid"
                                    alt="Icon"
                                />
                            </div>
                            <div className="col-md-9 ps-2 text-start">
                                <p className="fw-bold mb-0">Jodhpur</p>
                                <p className="address">
                                    B - 7 , Saraswati Nagar, Nr. St. Anne's School , Basni - 1
                                </p>
                            </div>
                        </div>

                        <div className="d-flex justify-content-start align-items-center">
                            <div className="col-3 col-md-2 p-3">
                                <img
                                    src={LocalImages?.calforniaofc}
                                    className="img-fluid"
                                    alt="Icon"
                                />
                            </div>
                            <div className="col-md-9 ps-2 text-start">
                                <p className="fw-bold mb-0">California</p>
                                <p className="address">
                                    1525 McCarthy Blvd, 1039,Milpitas, CA - 95035
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="d-flex justify-content-start px-3 py-2">
                            <div className="  icons   me-3 rounded-circle">
                                <CallIcon className="iconColor" />
                            </div>
                            <div className="col-md-10 d-flex align-items-center">
                                +91-8949744910
                            </div>
                        </div>
                        <div className="d-flex justify-content-start px-3 py-2">
                            <div className="icons rounded-circle me-3">
                                <EmailIcon className="iconColor" />
                            </div>
                            <div className="col-md-10 d-flex align-items-center text-break">
                                {" "}
                               {props?.data?.emailId}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
)};

export default DropForm;