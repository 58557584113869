import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import "../Assets/CSS/volunteer.css";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import LocalImages from '../Constant/localImages';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
const productivity = [
  {
    _id: 1,
    slideImage: LocalImages.romillamam,
    name: "Romila Tutoo",
    position: "Chief Marketing Officer (CMO)",
    slideText: "Rashmii is a beacon of hope, making primary healthcare accessible and empowering girls through education and skill training. Their motto, 'Educate a Girl, Empower a Nation,' resonates deeply. Join us in supporting Rashmii's mission to create a better, more equitable world. Together, we can uplift communities and change lives."
  },
  {
    _id: 2,
    slideImage: LocalImages.djsir,
    name: "Digambar Jagtap",
    position: "Chief Operating Officer (COO)",
    slideText: "Rashmi is a remarkable organization devoted to uplifting girls and empowering them to succeed. With a focus on education, personal development, and community support, Rashmi provides invaluable opportunities for girls to grow and thrive. Their efforts not only address immediate needs but also lay the groundwork for long-term success and self-confidence. Rashmi's work is a powerful testament to the impact of dedicated support and compassion in transforming lives and creating a brighter future for young girls."
  },
  {
    _id: 3,
    slideImage: LocalImages.sondgesir,
    name: "Dadasaheb Sondge",
    position: "Chief Executive Officer (CEO)",
    slideText: "Rashmi stands out for its unwavering dedication to the empowerment of girls. By offering crucial educational resources, mentorship, and a supportive community, Rashmi creates pathways for girls to overcome obstacles and achieve their dreams. The organization’s holistic approach not only addresses immediate needs but also fosters lasting confidence and leadership skills. Rashmi’s impact extends beyond individual lives, contributing to a more equitable and hopeful future for communities at large. Their work embodies the power of compassion and the transformative effect of investing in young girls' potential."
  },
];
export default function VoulnteerSwiper() {

  const [selectedIndex, setSelectedIndex] = useState<any>(0)
  const [activeSlide, setactiveSlide] = useState<any>();
  // const [swiper, setSwiper] = useState(null)
  // useEffect(() => {
  //   if (swiper) {
  //     console.log("swiper", swiper);

  //   }
  // }, [swiper])

  useEffect(() => {
    if (selectedIndex === -1) {
      setSelectedIndex(productivity?.length - 1)
    }
    else if (selectedIndex === productivity?.length) {
      setSelectedIndex(0)
    }
  }, [selectedIndex])


  const handleNext = () => {
    setSelectedIndex(selectedIndex+1);
  };


  const handlePrevious = () => {
    setSelectedIndex(selectedIndex-1);
  };


  return (
    <div className='swiper-container'>
      <div className='swiper-wrapper'>
      {productivity.length > 0 && (<div className='swiper-slide'>
          <div className="font30 fw-bold my-4 text-center text-xl-start">
            Volunteering Speak
          </div>
          <div className='speechtext'>
            {productivity[selectedIndex]?.slideText}
          </div>
          <div className='d-flex justify-content-evenly'>
            <button className='btn fs-1 border-white text-primary' onClick={handlePrevious}>
              <NavigateBeforeIcon fontSize="large" />
            </button>
            <div>
              <div className='volunteerImage'>
                <img src={productivity[selectedIndex]?.slideImage} alt={productivity[selectedIndex]?.name} className='slidingImg' />
              </div>
              <div className='font12 fw-bold text-center volunteer-name mt-2'>
                <div>
                  {productivity[selectedIndex]?.name}
                </div>
                <div>
                  {productivity[selectedIndex]?.position}
                </div>
              </div>
            </div>
            <button className='btn fs-1 border-white text-primary' onClick={handleNext}>
              <NavigateNextIcon fontSize="large" />
            </button>
          </div>
        </div> )}
      </div>
    </div>
  );
}

