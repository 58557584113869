import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../Assets/CSS/footer.css";
import LocalImages from "../Constant/localImages";
import YouTubeIcon from "@mui/icons-material/YouTube";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TelegramIcon from "@mui/icons-material/Telegram";
// /import QuoraIcon from '@mui/icons-material/Quora'
interface props {
  id?: string;
}
export default function Footer({ id }: props) {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const handleSubmit = () => { };
  const gotoLink = (val: string) => {
    navigate("/" + val);
    window.scrollTo(0, 0);
  }
  return (
    <footer
      className="footer  d-flex justify-content-center bgLightGrey pb-4 p-4"
      id={id}
    >
      <div className="col-md-12">
        <div className="row d-flex justify-content-between my-3 mx-md-5 pb-3 border-bottom ">
          <div className="col-md-2">
            <div className="border-bottom  ">
              <h6 className="fw-bold ">ABOUT US</h6>
            </div>
            <p className="mt-2"> About Rashmii</p>
            <p> Impact</p>
            <p> Reach & Presence </p>
            <p> Terms & Condition</p>
            <p> Privacy Policy</p>
            <p onClick={() => gotoLink('Invitation')} className="cursor"> Invitation</p>
          </div>
          <div className="col-md-2">
            <div className="border-bottom ">
              <h6 className="fw-bold ">OUR WORK</h6>
            </div>
            <p className="mt-2"> Education</p>
            <p> Health </p>
            <p> Women Empowerment</p>
            {/* <p> Our Responce</p> */}
          </div>
          <div className="col-md-2">
            <div className="border-bottom ">
              <h6 className="fw-bold ">CAMPAIGNS</h6>
            </div>
            <p className="mt-2"> Nanhi Kali</p>
            <p>Udaan</p>
            <p> Shiksha Na Ruke </p>
            <p> Tayyari Kal Ki</p>
          </div>
          <div className="col-md-2">
            <div className="border-bottom  ">
              <h6 className="fw-bold ">GET INVOLVED</h6>
            </div>
            <p className="mt-2 cursor" onClick={() => gotoLink('OurTeam')}>Our Team</p>
            <p>Individual Support</p>
            <p>Corporate Partnership</p>
            <p>Volunteer</p>
            <p>Careers</p>
          </div>
          <div className="col-md-3 mt-4 mt-md-0">
            <div className="border-bottom mb-2">
              <h6 className="fw-bold ">HEADQUARTER</h6>
            </div>
            <div className="d-flex justify-content-start align-items-center">
              <div className="col-3 col-md-2 text-center">
                <img src={LocalImages?.headquarter} className="img-fluid" alt="Icon" />
              </div>
              <div className="col-md-9 ps-2">
                <p className="fw-bold mb-0"> Jodhpur</p>
                <p className=" ">
                  11, First Floor, Umaid Heritage, Ratanada, Jodhpur
                </p>
              </div>
            </div>
            <div className="d-flex justify-content-start align-items-center">
              <div className="col-3 col-md-2 text-center">
                <img src={LocalImages?.pune} className="img-fluid" alt="Icon" />
              </div>
              <div className="col-md-9 ps-2">
                <p className="fw-bold mb-0"> Pune</p>
                <p className=" ">
                  9, Skyii Aquila Co-Operative Society, Baner, Pune
                </p>
              </div>
            </div>
            <div className="d-flex justify-content-start  align-items-center">
              <div className="col-3 col-md-2 text-center">
                <img src={LocalImages?.california} className="img-fluid" alt="Icon" />
              </div>
              <div className="col-md-9 ps-2">
                <p className="fw-bold mb-0"> California</p>
                <p className=" ">
                  1525 McCarthy Blvd, 1039,Milpitas, CA - 95035
                </p>

              </div>
            </div>
          </div>
          {/* <div className="col-md-2">
            <div className="border-bottom ">
              <h6 className="fw-bold ">RESOURCE CENTER</h6>
            </div>
            <p className="mt-2">Annual Report</p>
            <p>Newsletter</p>
            <p>Stories of change</p>
            <p>Blog</p>
          </div> */}
        </div>
        {/* <div className="row d-flex justify-content-between mb-3 mx-md-5 mt-5 border-bottom ">
          <div className="col-md-3 text-start">
            <div className="col-3 col-md-6  p-2 rounded mb-2">
              <img src={LocalImages.footerlogo} className="img-fluid" alt="logo"/>
            </div>
            <p>
              Let’s grow up your education level up, We are here to help you
              keep focus when studying
            </p>
            <div className="col-md-3 border-bottom "></div>
            <div className="row d-flex justify-content-start py-3 py-md-5 ">
              <div className="col-2">
                <TwitterIcon />
              </div>
              <div className="col-2">
                <InstagramIcon />
              </div>
              <div className="col-2">
                <FacebookRoundedIcon />
              </div>
              <div className="col-2">
                {" "}
                <YouTubeIcon />
              </div>
              <div className="col-2">
                {" "}
                <TelegramIcon />
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <p>SUBSCRIBE TO OUR NEWSLETTER</p>
           <form className="" onSubmit={handleSubmit}>
           <input
                className="footerInputs mb-4 w-75"
                type="text"
                name="username"
                id="email"
                value={username}
                placeholder="Full Name"
                onChange={(e) => {
                    setUsername(e.target.value);
                  }}
              />
              <input
                className="footerInputs mb-4  w-75"
                type="email"
                name="email"
                id="email"
                value={email}
                placeholder="Email"
                onChange={(e) => {
                    setEmail(e.target.value);
                  }}
              />
              <div>
                <button className="btn btn-light px-4">SUBSCRIBE</button>
              </div>
           </form>
          </div>
          <div className="col-md-4 mt-4 mt-md-0">
            <p>HEADQUARTER</p>
            <div className="d-flex justify-content-start">
              <div className="col-3 col-md-2 text-center">
                <img src={LocalImages?.headquarter} className="img-fluid" alt="Icon"/>
              </div>
              <div className="col-md-7 ps-2">
                <p className="fw-bold mb-2"> Jodhpur</p>
                <p>
                  B - 7 , Saraswati Nagar, Nr. St. Anne's School , Basni - 1
                </p>
              </div>
            </div>
            <div className="d-flex justify-content-start">
              <div className="col-3 col-md-2 text-center">
                <img src={LocalImages?.pune} className="img-fluid" alt="Icon"/>
              </div>
              <div className="col-md-7 ps-2">
                <p className="fw-bold mb-2"> Pune</p>
                <p>
                ISH Infotech, Phase 1, Hinjewadi, Pune,
                </p>
              </div>
            </div>
            <div className="d-flex justify-content-start">
              <div className="col-3 col-md-2 text-center">
                <img src={LocalImages?.california} className="img-fluid" alt="Icon"/>
              </div>
              <div className="col-md-7 ps-2">
                <p className="fw-bold mb-2"> California</p>
                <p className="mb-0">
                1525 McCarthy Blvd, 1039,Milpitas,
                </p>
                <p>CA - 95035</p>
              </div>
            </div>
          </div>
        </div> */}
        <div className="d-flex mx-md-5 flex-wrap align-items-center  justify-content-center">
          <div className="d-flex align-items-center flex-fill flex-wrap justify-content-center justify-content-md-start">
            <img src={LocalImages.footerlogo} className="footerLogo me-5" alt="logo" />
            <div className="row d-flex justify-content-between  my-3 my-md-0">
              <div className="col-2">
                <TwitterIcon />
              </div>
              <div className="col-2">
                <a href={"https://www.instagram.com/rashmiiorg"} style={{ color: "#fff" }} target="_blank" rel="noopener noreferrer">
                  <InstagramIcon />
                </a>
              </div>
              <div className="col-2">
                <a href={"https://www.facebook.com/rashmiiorg"} style={{ color: "#fff" }} target="_blank" rel="noopener noreferrer">
                  <FacebookRoundedIcon />
                </a>
              </div>
              <div className="col-2">
                {" "}
                <a href={"https://www.youtube.com/@rashmiiorg"} target="_blank" rel="noopener noreferrer" style={{ color: "#fff" }}>
                  <YouTubeIcon />
                </a>
              </div>
              {/* <div className="col-2">
                {" "}
                <TelegramIcon />
              </div> */}
            </div>
          </div>
          <div className="mt-2 mt-md-0 mb-2">© 2024 All rights reserved Rashmi Welfare Society</div>
        </div>
      </div>
    </footer>
  );
}
