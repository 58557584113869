const endpoint = {
  academic: {
    addBookSession: "/v1/academic/add",
  },
  general: {
    addContactUs: "/v1/general/contactUs",
    addUser: "/v1/general/addUser",
  },
};
export default endpoint;
