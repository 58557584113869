import React, { useState } from "react";
import Header from "../Component/header";
import HomeBannerSlider from "../Component/homeBannerSlider";
import "../Assets/CSS/home.css";
import LocalImages from "../Constant/localImages";
import AowCard from "../Component/aowCard";
import PartnerCompanies from "../Component/partnerCompanies";
import OurVoiceSwiper from "../Component/ourVoiceSwiper";
import ContactPopUP from "../Component/contactPopup";
import Footer from "../Component/footer";
import GetInvoled from "../Component/getInvoled";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination } from "swiper/modules";
import { useNavigate } from "react-router-dom";
import getYouTubeThumbnail from "yt-vimeo-thumbnail";
import YTThumbnailGirlBook from "../Component/ytThumbnailGirlBook";
export default function Home() {
  const [open, setOpen] = useState<boolean>(false);
  const [isHidden, setisHidden] = useState<boolean>(true);
  const navigate = useNavigate();
  const handleClose = () => {
    setOpen(false);
  };


  const areaOfWorkData1 = [
    {
      bannerImg: LocalImages?.aowImg1,
      Title1: "Nanhi Kali",
      icon: LocalImages?.nanhiKaliIcon,
      Title2: "IMPACT",
      subcontent: "Making Primary Healthcare Accessible for All Girls",
    },
    {
      bannerImg: LocalImages?.aowImg2,
      Title1: "Udaan",
      icon: LocalImages?.ladderIcon,
      Title2: "IMPACT",
      subcontent: "Educate a Girl, Empower a Nation",
    },
    {
      bannerImg: LocalImages?.aowImg3,
      Title1: "Shiksha Na Ruke",
      icon: LocalImages?.educationIcon,
      Title2: "IMPACT",
      subcontent: "A Girl with a Book is a Vaccine Against Poverty",
    },
    {
      bannerImg: LocalImages?.aowImg4,
      Title1: "Tayyari Kal Ki",
      icon: LocalImages?.tayariKalKi,
      Title2: "IMPACT",
      subcontent: "Enabling Livelihoods for Girls through Skill Training",
    },
  ];

  const newsRoomData = [
    {
      _id: 1,
      bannerImg: LocalImages?.newsImg1,
      Title: "EDUCATION",
      subTitle: "Digital Revolution",
      text: "Making Digital Learning Accessible to All",
    },
    {
      _id: 2,
      bannerImg: LocalImages?.newsImg2,
      Title: "Career",
      subTitle:
        "Divya Sonwane honored as Rashmii's 2024 Woman of Professional Excellence.",
      text: "",
    },
    {
      _id: 3,
      bannerImg: LocalImages?.newsImg3,
      Title: "Hostel",
      subTitle:
        "Rashmii unveils a new hostel for girls in Pune.",
      text: "",
    },
    {
      _id: 4,
      bannerImg: LocalImages?.newsImg4,
      Title: "Health Care",
      subTitle: "Feeding the Future: Meals for Needy Children by Rashmii",
      text: "",
    },
  ];

  const hiringPartener = [
    {
      imgPath: LocalImages.DhisterLogo,
    },
    {
      imgPath: LocalImages.ifasLogo,
    },
    {
      imgPath: LocalImages.lurnigoLogo,
    },
    {
      imgPath: LocalImages.twoBrothersLogo,
    },
    {
      imgPath: LocalImages.SpanIdea,
    },
    {
      imgPath: LocalImages.Matellio,
    },
    {
      imgPath: LocalImages.DhisterLogo,
    },
    {
      imgPath: LocalImages.ifasLogo,
    },
    {
      imgPath: LocalImages.lurnigoLogo,
    },
    {
      imgPath: LocalImages.twoBrothersLogo,
    },
    {
      imgPath: LocalImages.SpanIdea,
    },
    {
      imgPath: LocalImages.Matellio,
    },
    {
      imgPath: LocalImages.DhisterLogo,
    },
    {
      imgPath: LocalImages.ifasLogo,
    },
    {
      imgPath: LocalImages.lurnigoLogo,
    },
    {
      imgPath: LocalImages.twoBrothersLogo,
    },
    {
      imgPath: LocalImages.SpanIdea,
    },
    {
      imgPath: LocalImages.Matellio,
    },
    {
      imgPath: LocalImages.DhisterLogo,
    },
    {
      imgPath: LocalImages.ifasLogo,
    },
    {
      imgPath: LocalImages.lurnigoLogo,
    },
    {
      imgPath: LocalImages.twoBrothersLogo,
    },
    {
      imgPath: LocalImages.SpanIdea,
    },
    {
      imgPath: LocalImages.Matellio,
    },
    {
      imgPath: LocalImages.DhisterLogo,
    },
    {
      imgPath: LocalImages.ifasLogo,
    },
    {
      imgPath: LocalImages.lurnigoLogo,
    },
    {
      imgPath: LocalImages.twoBrothersLogo,
    },
    {
      imgPath: LocalImages.SpanIdea,
    },
    {
      imgPath: LocalImages.Matellio,
    },
    {
      imgPath: LocalImages.DhisterLogo,
    },
    {
      imgPath: LocalImages.ifasLogo,
    },
    {
      imgPath: LocalImages.lurnigoLogo,
    },
    {
      imgPath: LocalImages.twoBrothersLogo,
    },
    {
      imgPath: LocalImages.SpanIdea,
    },
    {
      imgPath: LocalImages.Matellio,
    },
  ];

  var myScrollFunc = function () {
    // let offsetHeight = stageCanvasRef.current.offsetHeight;
    // if (window.innerWidth >= 768) {
    var y = window.scrollY;
    const maxScroll = document.body.scrollHeight - window.innerHeight;
    if (y >= 500 && y < maxScroll - 300) {
      setisHidden(false);
    } else {
      setisHidden(true);
    }
    // } else {
    //   setisHidden(false);
    // }
  };
  window.addEventListener("scroll", myScrollFunc);

  const ourPeopleData = [
    {
      imgPath: LocalImages?.ourPeopleBanner1,
      subText:
        "Radheshyam Choudhary, a dynamic serial entrepreneur, has co-founded six trailblazing companies spanning the EdTech, HealthCare, and IT sectors, leaving an indelible mark with his visionary leadership. Beyond business, his heart beats for philanthropy, embodied in the Rashmi Welfare Society he initiated. A true hero of society, he inspires countless lives with his mission to ignite a ripple of empowerment, ensuring educated and skilled women pave the way for a brighter, more prosperous India.",
    },
    {
      imgPath: LocalImages?.ourPeopleBanner2,
      subText:
        "Roopa Ram Patel, a beacon of hope, has been uplifting underprivileged students for two decades. Honored multiple times by the President of India and the Governors of Rajasthan, his dedication is unparalleled. Under his mentorship, thousands have soared to remarkable heights in global MNCs, both in India and abroad. Roopa Ram's unwavering commitment to education makes him a true hero of society, inspiring countless lives.",
    },
  ];
  const bannerData = [
    {
      imgPath: LocalImages?.homeBannerImg1,
      title: "Nanhi Kali",
      subText: "Making Primary Healthcare Accessible for All Girls",
    },
    {
      imgPath: LocalImages?.homeBannerImg2,
      title: "She Can Fly",
      subText: "Educate a Girl, Empower a Nation",
    },
    {
      imgPath: LocalImages?.homeBannerImg3,
      title: "Shiksha Na Ruke",
      subText: "A Girl with a Book is a Vaccine Against Poverty",
    },
    {
      imgPath: LocalImages?.homeBannerImg4,
      title: "Tayyari kal Ki",
      subText: "Enabling Livelihoods for Girls Through Skill Training",
    },

  ]
  const gotoLink = (val: string) => {
    navigate("/" + val)
  }

  return (
    <div>
      <Header />
      <div className="homeBanner">
        <HomeBannerSlider data={bannerData} />
      </div>
      <div className="aow commonPad mt-5">
        <div className="row">
          {/* <div className="col-md-12 col-xl-6 d-flex justify-content-center">
            <div className="girlBook">
            <img src={LocalImages?.videoicon} alt="videoicon" className="playVideoIcon"/>
              <video muted autoPlay loop>
                <source src={LocalImages?.VideoCompLab} type="video/mp4" />
              </video>
            </div>
          </div> */}
          <YTThumbnailGirlBook />
          <div className="col-md-12 col-xl-6">
            <div className="font24 fw-bold mt-4 headingborder">
              Enabling 'Kal ka Classroom' to nurture future-ready girls
            </div>
            <div className="mt-4 font18 text-secondary">
              Rashmii envisions a future where rural students receive top-tier technical education, on par with urban standards, empowering them to contribute significantly to national development. Our cutting-edge digital computer lab, housed in a fully furnished, air-conditioned building, features 40 high-performance computer sets, a state-of-the-art projector, pen tablets, and high-speed internet, offering exceptional technological immersion.
            </div>
            <div className="mt-3">
              <button className='pinkBtn font16 py-2 px-5 rounded fw-bold my-4'>Donate</button>

            </div>
          </div>
        </div>
      </div>

      <div className="aow commonPad">
        <div className="text-center">
          <div className="font48 fw-bold mb-2">Area Of Work <span>
            <img
              src={LocalImages?.ourStoryIcon}
              alt="Our Story"
              className="headingImg"
            />
          </span></div>
          <div className="font18 Subheadingborder d-inline-block pb-2">
            Bringing Smiles to Girls Through Education & Empowerment
          </div>
        </div>
        <div className="mt-4">
          <div className="row">
            {areaOfWorkData1?.map((item, index) => (
              <div className="col-md-6 mb-3">
                <AowCard data={item} count={index} />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="ourStory commonPad">
        <div className="text-center">
          <div className="font48 fw-bold pb-2 ">
            Our Story{" "}
            <span>
              <img
                src={LocalImages?.areaWorkIcon}
                alt="Our Story"
                className="headingImg"
              />
            </span>
          </div>
          <div className="font18 Subheadingborder d-inline-block pb-2">
            Transforming Lives, Empowering Futures
          </div>
        </div>
        <div className="rounded border mt-3 mt-md-4 p-3 p-md-5">
          <div className="row">
            <div className="col-md-7 order-2 order-md-1">
              <div className="text-center text-md-start">
                <div className="Subheadingborder d-inline-block textPink ourStorySubheading boldfont">
                  RASHMI CHOUDHARY
                </div>
              </div>

              <div className="font16 mt-3 textJustify">
                <b>Rashmii</b> envisions a world where every girl, regardless of
                her socio-economic background, has the opportunity to transform
                her life through education.
              </div>
              <div className="font16 mt-3 textJustify">
                From the moment a girl is born, Rashmii steps in to provide
                financial support to disadvantaged families, ensuring every{" "}
                <b>"Nanhi Kali"</b> is nurtured with care.
              </div>
              <div className="font16 mt-3 textJustify">
                Guided by the principle, "
                <b>Educate a Girl, Empower a Nation </b>," we ensure every girl
                has access to quality education.
              </div>
              <div className="font16 mt-3 textJustify">
                Our commitment extends beyond education. Through our "
                <b>Tayyari Kal Ki</b>" skill training programs, we empower girls
                to grow into strong, empowered women who become catalysts for
                change in their communities and beyond.
              </div>
              <div className="font16 mt-3 textJustify">
                Rashmii is more than just a beacon of hope; it's a movement for
                change. Our vision is to create a ripple effect of empowerment,
                where educated and skilled women lead the way to a brighter,
                more prosperous future for India.{" "}
                <b>
                  Together, we are transforming lives and empowering futures,
                  one girl at a time.
                </b>
              </div>
              {/* <button className='pinkBtn font16 py-2 px-5 rounded fw-bold mt-5'>Read More</button> */}
            </div>
            <div className="col-md-5 text-center text-md-end mb-4 mb-md-0  order-1 order-md-2">
              <img
                src={LocalImages?.rashmi}
                alt="RASHMI CHOUDHARY"
                className="rashmiImg rounded"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="commonPad">
        <div className="text-center">
          <div className="font48 fw-bold mb-2 pb-2 ">Stories Of Change<img
            src={LocalImages?.StoriesOfChangeIcon}
            alt="Our Story"
            className="headingImg ms-3"
          /></div>
          <div className="font18 Subheadingborder d-inline-block pb-2">
            Empowering Girls, Transforming India
          </div>
        </div>
        <div className="mt-4">
          <div className="row mx-0">
            <div className="col-md-6 mb-3 px-0 borderRightWhite">

              <div className="overflow-hidden position-relative">
                {/* <img
                  src={LocalImages?.storyChangeBanner}
                  className="storyChangeBannerImg"
                  alt="Stories Of Change"
                /> */}
                <video muted autoPlay loop className='ourVoiceVideo'>
                  <source src={LocalImages?.ClassroomTransform} type="video/mp4" />
                </video>
                <div className="storyChangeOverlay">
                  <div className="font24 boldfont">
                    Branching Out: Doli Government Schools' Journey from Trees
                    to Tech
                  </div>
                  <div className="mt-4">
                    <button className="readMoreWhite py-2 px-4 rounded">
                      Read More
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 px-0 borderRightWhite">
              <div className="row mx-0">
                {/* <div className='col-md-6 p-0' style={{ background: "#353535" }}>
                  <div className='yellowBorder'></div>
                  <div className='storyChangeContent1'>
                    <div className='font20 fw-bold'>From Theory to Practice:</div>
                    <div className='ellipsis6 mt-3'>Chemistry & Physics Laboratory in Rajasthan Government School.</div>
                    <button className='mt-4 py-2 px-4 border-0 fw-bold rounded'>Read More</button>
                  </div>
                </div> */}
                <div className="col-md-12 p-0 position-relative socTopBox">
                  <img
                    src={LocalImages?.soc1}
                    alt="pooja"
                    className="w-100 aspect168"
                  />
                  <div className="textOverlay">
                    <div className="font20 boldfont">
                      From Theory to Practice: Chemistry & Physics Laboratory in
                      Rajasthan Government School.
                    </div>
                    <div className="mt-3">
                      <button className="readMoreWhite py-2 px-4 rounded d-none d-md-inline-block">
                        Read More
                      </button>
                      <button className="pinkBtn py-1 px-4 rounded font12 d-flex d-md-none">
                        Read More
                      </button>
                    </div>
                  </div>
                </div>
                {/* <div className='d-flex px-0'>
                <div className='yellowBorder'></div><div className='pinkBorder' style={{ margin: "auto 0 auto auto" }}></div>
                </div> */}

                <div className="col-md-12 p-0 position-relative socBottomBox">
                  <img
                    src={LocalImages?.soc2}
                    alt="poorvi"
                    className="w-100 aspect168"
                  />
                  <div className="textOverlay">
                    <div className="font20 boldfont">
                      From Kitchen to Boardroom: Building Women Entrepreneurs
                    </div>

                    <div className="mt-3">
                      <button className="readMoreWhite py-2 px-4 rounded d-none d-md-inline-block">
                        Read More
                      </button>
                      <button className="pinkBtn py-1 px-4 rounded font12 d-flex d-md-none">
                        Read More
                      </button>
                    </div>
                  </div>
                </div>
                {/* <div className='col-md-6 p-0' style={{ background: "#353535" }}>
                  <div className='pinkBorder' style={{ margin: "auto 0 auto auto" }}></div>
                  <div className='storyChangeContent2'>
                    <div className='font20 fw-bold'>Meet Purvi : Youth Champion & Young Doctor</div>
                    <button className='mt-4 py-2 px-4 border-0 fw-bold rounded'>Read More</button>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="commonPad">
        <div className="text-center">
          <div className="font48 fw-bold mb-2 pb-2 ">
            Spotlight{" "}
            <span>
              <img
                src={LocalImages?.SpotlightIcon}
                alt="Newsroom"
                className="headingImg"
              />
            </span>
          </div>
          <div className="font18 Subheadingborder d-inline-block pb-2">
            Explore Our Latest News and Events
          </div>
        </div>
        <div className="mt-4">
          <div className="row mx-0">
            <div className="col-md-7 mb-3 px-0 pe-md-3">
              <div className="rounded overflow-hidden position-relative">
                <img
                  src={newsRoomData[0]?.bannerImg}
                  className="firstNewsRoom"
                  alt={newsRoomData[0]?.Title}
                />
                <div className="textOverlay">
                  <div className="font18 fw-bold my-2 ellipsis2">
                    {newsRoomData[0]?.subTitle}
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="ellipsis2">{newsRoomData[0]?.text}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5 ">
              {newsRoomData?.map((item, index) => {
                if (index > 0 && index < 4) {
                  return (
                    <div className="mb-3">
                      <div className="row border rounded">
                        <div className="col-md-5 px-0">
                          <img
                            src={item?.bannerImg}
                            alt={item?.Title}
                            className="w-100 rounded aspect32"
                          />
                        </div>
                        <div className="col-md-6 py-2">
                          <div className="font16 fw-bold textPink">
                            {item?.Title}
                          </div>
                          <div className="fw-bold my-1 ellipsis2">
                            {item?.subTitle}
                          </div>
                          <div className="d-flex align-items-center font12">
                            <div className="ellipsis2">{item?.text}</div>
                          </div>
                          <div className="mt-4">
                            <button className="pinkBtn py-1 px-4 rounded font12">
                              Read More
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
      </div>

      <div className="commonPad">
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          modules={[Autoplay, Pagination]}
          className="mySwiper"
        >
          {ourPeopleData?.map((item, index) => (
            <SwiperSlide key={index}>
              <div className="rounded overflow-hidden position-relative">
                <img
                  src={item?.imgPath}
                  alt="Our People"
                  className="w-100 aspect169"
                />
                <div className="spotlightOverlay">
                  <div className="font48">
                    Our People{" "}
                    <span className="fw-bold font48 boldfont mb-3">
                      #Who make it happen
                    </span>{" "}
                  </div>

                  <div>{item?.subText}</div>
                  <button className=" mt-4 py-2 px-4 rounded btnYellow">
                    Read More
                  </button>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        {/*  */}
      </div>

      <div className="commonPad">
        <div className="text-center">
          <div className="font48 fw-bold mb-2 pb-2 ">
            Our Partners in Change<img
              src={LocalImages?.ourPartnersIcon}
              alt="Our Story"
              className="headingImg ms-3"
            />
          </div>
          <div className="font18 Subheadingborder d-inline-block pb-2">
            Together We Can, Together We Will - Bringing Dreams to Life
          </div>
        </div>
        <div className="mt-4 hiringPartner">
          <PartnerCompanies data={hiringPartener} />
        </div>
        {/* <div className='mb-5'>
          <button className='btn btn-primary' onClick={openpopup}> open popup</button>
        </div> */}
      </div>

      <div className="commonPad">
        <div className="text-center">
          <div className="font48 fw-bold mb-2 pb-2 ">HeartSpeak <img
            src={LocalImages?.HeartSpeakIcon}
            alt="Our Story"
            className="headingImg"
          /></div>
          <div className="font18 Subheadingborder d-inline-block pb-2">
            Voices of Change, Hearts of Strength
          </div>
        </div>
        <div className="mt-4 hiringPartner">
          <OurVoiceSwiper />
        </div>

      </div>

      <GetInvoled openPopup={() => setOpen(true)} />
      <ContactPopUP open={open} handleClose={handleClose} />
      <Footer />
      <div className="donateFixed d-flex">
        <img
          src={LocalImages?.donateIcon}
          alt="donate"
          className="donateImg me-3"
        />
        <div className="font18">Donate</div>
      </div>
      {/* {!isHidden ? (
        <div className="fixedFooter">
          <div className="row mx-0">
            <div className="col-3 col-md-9 d-flex align-items-center justify-content-center justify-content-md-start mb-2 mb-md-0 flex-wrap">
              <div className="me-3">
                <img
                  src={LocalImages?.logoIcon}
                  alt="Logo"
                  className="fixedFooterLogo cursor"
                />
              </div>
              <div className="fw-bold d-none d-md-block">
              Revolutionizing Rural Education by Rashmii - Join us on 28 July 2024 at Senior Secondary School, Doli, Jodhpur
              </div>
            </div>
            <div className="col-9 col-md-3 d-flex align-items-center justify-content-center justify-content-md-end flex-wrap position-relative">
              <button
                className=" rounded fw-bold font16 bgPink py-2 px-5 border-0" onClick={()=>gotoLink('Invitation')}
              >
                Inauguration Details
              </button>
              
            </div>
          </div>
        </div>
      ) : null} */}
    </div>
  );
}
