import React from 'react';
import LocalImages from '../Constant/localImages';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { useNavigate } from 'react-router-dom';
export default function GetInvoled(props:any) {
  const navigate = useNavigate();
  const gotoLink=(val:string)=>{
    navigate("/"+val);
    window.scrollTo(0, 0);
  }
  return (
    <footer>
      <div className='row mx-0 w-100'>
        <div className='col-md-2 bgPink p-4 d-flex align-items-center justify-content-center'>
            <div className='font20 fw-bold'>GET INVOLVED</div>
            <div><KeyboardDoubleArrowRightIcon className='font48 ms-2'/></div>
        </div>
              <div className='col-md-10'>
                  <div className='row h-100'>
                      <div className='col-md-4 bgLightYellow p-3 text-center cursor' onClick={()=>gotoLink('Contact')}>
                          <div><img src={LocalImages?.getInTouch} alt='GET IN TOUCH' className='getInTouchImmg mb-1'/></div>
                          <div className='font20'>GET IN TOUCH</div>
                      </div>
                      <div className='col-md-4 bgYellow p-3 text-center border-start border-end cursor' onClick={()=>gotoLink('Volunteer')}>
                      <div><img src={LocalImages?.becomeVolinter} alt='BECOME A VOLINTER' className='getInTouchImmg mb-1'/></div>
                          <div className='font20'>BECOME A VOLUNTEER</div>
                      </div>
                      <div className='col-md-4 bgLightYellow p-3 text-center cursor'>
                      <div><img src={LocalImages?.donate} alt='DONATE NOW' className='getInTouchImmg mb-1'/></div>
                          <div className='font20'>DONATE NOW</div>
                      </div>
                  </div>
              </div>        
    </div>  
    </footer>
  );
}
