import React, { useState } from "react";
import LocalImages from "../Constant/localImages";
import "../Assets/CSS/header.css";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
export default function Header() {
  const navigate = useNavigate();

  const handleClick = (value: any) => {
    if (value === "logo") {
      navigate("../");
    } else if (value === "contact") {
      navigate("../contact");
    }
  };

  const gotoLink = (val: string) => {
    navigate("/" + val)
}

  const [showMenu, setshowMenu] = useState<boolean>(false);
  return (
    // <header className={`header d-flex align-items-center ${isHidden ? 'hide' : 'show'}`}>
    <header className="header d-flex align-items-center">
      <div>
        <img
          src={LocalImages?.logo}
          alt="Logo"
          className="headerLogo cursor"
          onClick={() => {
            handleClick("logo");
          }}
        />
      </div>
      <div className="d-none d-md-flex align-items-center flex-fill me-5 position-relative justify-content-end">
        <div className="headerMenu font16">
          <span className="position-relative">About Us</span>
          <div className="subMenuPopup">
            <div className="headersubMenu border-bottom" onClick={()=>gotoLink("OurTeam")}>Overview</div>
            <div className="headersubMenu border-bottom" onClick={()=>gotoLink("OurTeam")}>Leadrship</div>
            <div className="headersubMenu border-bottom" onClick={()=>gotoLink("OurTeam")}>Board of Trustees</div>
            <div className="headersubMenu border-bottom">Management Team</div>
            <div className="headersubMenu border-bottom">Annual Reports</div>
            <div className="headersubMenu border-bottom" onClick={()=>gotoLink("PartnerSupport")}>
              Partners & Supporters
            </div>
          </div>
        </div>
        <div className="headerMenu font16">
          <span className="position-relative">Our Work</span>
          <div className="subMenuPopup">
            <div className="headersubMenu border-bottom">Nanhi Kali</div>
            <div className="headersubMenu border-bottom">Udaan</div>
            <div className="headersubMenu border-bottom">Shiksha Na Ruke</div>
            <div className="headersubMenu border-bottom">Tayyari Kal Ki</div>
          </div>
        </div>
        <div className="headerMenu font16">
          <span className="position-relative">Impact Stories</span>
        </div>
        <div
          className="headerMenu font16"
          onClick={() => {
            handleClick("contact");
          }}
        >
          <span className="position-relative">Contact</span>
        </div>
      </div>
      <div className="d-none d-md-flex">
        <button className="btn btn-dark rounded px-5 py-2 font18">
          Donate
        </button>
      </div>
      <div className="d-flex flex-fill d-md-none justify-content-end">
        <MenuIcon onClick={() => setshowMenu(true)} />
      </div>
      <div className={`mobileMenuContainer ${showMenu ? "active" : ""}`}>
        <div className="d-flex align-items-center border-bottom pb-2 mb-3">
          <div>
            <img src={LocalImages?.logo} alt="Logo" className="headerLogo" />
          </div>
          <div className="d-flex flex-fill justify-content-end">
            <CloseIcon onClick={() => setshowMenu(false)} />
          </div>
        </div>
        <div>
          <div className="accordion rounded-0" id="accordionExample">
            <div className="accordion-item rounded-0">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  About Us
                </button>
              </h2>
              <div
                id="collapseOne"
                className="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body p-0">
                  <div className="border-bottom headerSubmenuMob">Overview</div>
                  <div className="border-bottom headerSubmenuMob">
                    Leadrship
                  </div>
                  <div className="border-bottom headerSubmenuMob">
                    Board of Trustees
                  </div>
                  <div className="border-bottom headerSubmenuMob">
                    Management Team
                  </div>
                  <div className="border-bottom headerSubmenuMob">
                    Annual Reports
                  </div>
                  <div className="headerSubmenuMob">Partners & Supporters</div>
                </div>
              </div>
            </div>
            <div className="accordion-item rounded-0">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  Our Work
                </button>
              </h2>
              <div
                id="collapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body p-0">
                  <div className="border-bottom headerSubmenuMob">
                    Nanhi Kali
                  </div>
                  <div className="border-bottom headerSubmenuMob">Udaan</div>
                  <div className="border-bottom headerSubmenuMob">
                    Shiksha Na Ruke
                  </div>
                  <div className="headerSubmenuMob">Tayyari Kal Ki</div>
                </div>
              </div>
            </div>
          </div>
          <div className="headerMenuMob">Impact Stories</div>
          <div className="headerMenuMob">Contact</div>
        </div>
      </div>
    </header>
  );
}
