import endpoint from "../../Constant/endpoint";
import services from "../../Constant/service";
import { SET, RESET_STORE } from "../../Types/authTypes";
import { showErrorSnackbar, showSuccessSnackbar } from "./snackbarAction";

export const setInitialState = () => async (dispatch: any) => {
  dispatch({
    type: RESET_STORE,
  });
};

export const updateMoreValues = (values: any) => (dispatch: any) => {
  dispatch({
    type: SET,
    payload: values,
  });
};

export const setParentCourse = (key: any, value: any) => (dispatch: any) => {
  dispatch({
    type: SET,
    payload: {
      [key]: value,
    },
  });
};
export const addBookSession =
  (data: any, callback: Function, ErrorCallback: Function) =>
  async (dispatch: any, getState: any) => {
    dispatch(updateMoreValues({ isLoader: true }));
    services.postApiCall(
      endpoint.academic.addBookSession,
      data,
      (res: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res);
        } else ErrorCallback(res);
      },
      (error: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        ErrorCallback(error);
      }
    );
  };

export const addContactUs =
  (data: any, callback: Function, ErrorCallback: Function) =>
  async (dispatch: any, getState: any) => {
    dispatch(updateMoreValues({ isLoader: true }));
    services.postApiCall(
      endpoint.general.addContactUs,
      data,
      (res: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else ErrorCallback(res);
      },
      (error: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        ErrorCallback(error);
      }
    );
  };

export const addUser =
  (data: any, callback: Function, ErrorCallback: Function) =>
  async (dispatch: any, getState: any) => {
    dispatch(updateMoreValues({ isLoader: true }));
    services.postApiCall(
      endpoint.general.addUser,
      data,
      (res: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else ErrorCallback(res);
      },
      (error: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        ErrorCallback(error);
      }
    );
  };
