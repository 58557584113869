import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import "../Assets/CSS/imgPopup.css";
import ReactCrop from "react-image-crop";
import CloseIcon from '@mui/icons-material/Close';
interface props {
  open: boolean;
  handleClose: any;
  crop: any;
  setCrop: Function;
  setCompletedCrop: Function;
  profileImg: string;
  scale: number;
  rotate: number;
  onImageLoad: any;
  imgRef: any;
}
export default function ImgCropPopup({ open, handleClose, crop, setCrop, setCompletedCrop, profileImg, scale, rotate, onImageLoad, imgRef }: props) {
  function closePopup(
    event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ): void {
    handleClose();
  }
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="model"
      open={open}
      onClose={closePopup}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className="p-0 text-center position-relative popup">
          <div className="imgPopup">
            <div className="modal-container-header">
              <span className="modal-container-title">
                Crop Image
              </span>
              <CloseIcon onClick={closePopup}/>
            </div>
            <div className="modal-container-body">
              <ReactCrop crop={crop} onChange={(_, percentCrop) => setCrop(percentCrop)} minHeight={200} onComplete={(c) => setCompletedCrop(c)} locked={true}>
                {profileImg ? <img src={profileImg} alt='cardImg' className='' style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                  onLoad={onImageLoad} ref={imgRef} /> : null}
              </ReactCrop>
              <div >
                <button className="btn btn-primary" onClick={handleClose}>Submit</button>
              </div>
            </div>
            
          </div>

        </div>
      </Fade>
    </Modal>
  );
}
