import EastIcon from '@mui/icons-material/East';

export default function VolunteerActivity(props: any) {
    return (
        <div className='row position-relative aowCard shadow-sm activityCard rounded-2'>
            <div className="col-12 px-0">
                <img src={props?.data?.bannerImg} alt={props?.data?.Title1} className='posterImage' />
                <div className='px-3 py-2 font18 posterText'>{props?.data?.Title1}</div>
            </div>
            <div className='volunteerContent d-flex justify-content-between'>
                <div>
                <div className='fw-bold volunteerContentHeading mt-4 mb-2 mb-md-3 boldfont text-center'>
                    {props?.data?.Title2}
                </div>
                <div className='volunteerContentMiddleContainer px-3'>
                    {props?.data?.subcontent}
                </div>
                </div>
                <button className='mb-4 pinkReadBtn font16 py-2 ps-3 rounded fw-bold mt-3 mb-0 mx-3'>
                    Read More
                    <EastIcon />
                </button>
            </div>
        </div>

    );
}
