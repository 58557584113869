import React from "react";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { useAppDispatch, useAppSelector } from "../hooks";
import { SnackbarReducerModal } from "../Modals";

function ErrorSnackbar() {
  const dispatch = useAppDispatch();
  const { errorSnackbarOpen, message } = useAppSelector(
    (state: { uiReducer: SnackbarReducerModal }) => ({
      successSnackbarOpen: state.uiReducer.successSnackbarOpen,
      errorSnackbarOpen: state.uiReducer.errorSnackbarOpen,
      infoSnackbarOpen: state.uiReducer.infoSnackbarOpen,
      message: state.uiReducer.message,
    })
  );
  function handleClose() {
    dispatch({ type: "SNACKBAR_CLEAR" });
  }
  return (
    <Snackbar
      open={errorSnackbarOpen}
      autoHideDuration={100000}
      onClose={handleClose}
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        onClose={handleClose}
        severity="error"
        sx={{ width: "100%" }}
      >
        {message}
      </MuiAlert>
    </Snackbar>
  );
}

export default ErrorSnackbar;
