// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ourpartner {
    text-align: justify;
}

.logo {
    width: 200px;
    max-width: 100%;
    box-shadow: 0px 0px 2px #00000029!important;
    border-radius: 5px;
}

.contactmail{
    background-color: #fee14d;
}

.knowmore{
    font-size: 10px;
}`, "",{"version":3,"sources":["webpack://./src/Assets/CSS/partnerSupport.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,eAAe;IACf,2CAA2C;IAC3C,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".ourpartner {\n    text-align: justify;\n}\n\n.logo {\n    width: 200px;\n    max-width: 100%;\n    box-shadow: 0px 0px 2px #00000029!important;\n    border-radius: 5px;\n}\n\n.contactmail{\n    background-color: #fee14d;\n}\n\n.knowmore{\n    font-size: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
