import React, { useState } from "react";
import Header from "../Component/header";
import HomeBannerSlider from "../Component/homeBannerSlider";
import "../Assets/CSS/home.css";
import "../Assets/CSS/ourteam.css";
import "../Assets/CSS/partnerSupport.css"
import LocalImages from "../Constant/localImages";
import AowCard from "../Component/aowCard";
import PartnerCompanies from "../Component/partnerCompanies";
import OurVoiceSwiper from "../Component/ourVoiceSwiper";
import ContactPopUP from "../Component/contactPopup";
import Footer from "../Component/footer";
import GetInvoled from "../Component/getInvoled";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination } from "swiper/modules";
import { useNavigate } from "react-router-dom";
import ExpandMoreSharpIcon from '@mui/icons-material/ExpandMoreSharp';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import TeamCard from "../Component/teamCard";
import PartnerCard from "../Component/partnerCard";
export default function PartnerSupport() {
    const [open, setOpen] = useState<boolean>(false);
    const [selectedFounder, setselectedFounder] = useState<string>('');
    const [selectedTeam, setselectedTeam] = useState<string>('');
    const navigate = useNavigate();
    const handleClose = () => {
        setOpen(false);
    };

    const gotoLink = (val: string) => {
        navigate("/" + val)
    }

    const PartnerList = [
        {
            partnerLogo: LocalImages?.ifasLogo,
            partnerName: "IFAS Edutech Private Limited",
            partnerText: "IFAS Edutech Pvt Ltd is the fastest growing self-funded EdTech Company in India. IFAS is India's Best Online platform for delivering services to students at the Graduate and Postgraduate level in various streams of Science, Arts, and Commerce. It's mission is to empower students with the knowledge and skills they need to thrive in the careers of tomorrow.",
            partnerWebsite: "https://www.ifasonline.com",
        },
        {
            partnerLogo: LocalImages?.twoBrothersLogo,
            partnerName: "Two Brothers Publications Private Limited",
            partnerText: "Two Brothers Publications Private Limited specializes in publishing a wide range of books for children aged 3 to 15 years. Our collection includes academic texts, captivating mythology and engaging games and activity books. Two Brothers operate globally, serving kids in the USA, India, Australia, Africa, the Middle East and Europe, with educational and entertaining content for children.",
            partnerWebsite: "https://www.twobrothers.pub",
        },
        {
            partnerLogo: LocalImages?.DhisterLogo,
            partnerName: "Dhister Private Limited",
            partnerText: "DhiSter is India's premier Skill-Oriented Career School, Revolutionizing skill knowledge in the country. Dhister mission is to transform untrained and underutilized talent into skilled and well-trained job-ready candidates who meet industry standards.",
            partnerWebsite: "https://www.dhister.com",
        },
        {
            partnerLogo: LocalImages?.lurnigoLogo,
            partnerName: "Lurnigo LLC",
            partnerText: "Lurnigo is the ultimate one-stop solution for students aspiring to gain admission to Ivy League and top-tier universities for undergraduate programs. It’s the leading online platform for K12 education and test preparation in the USA, Australia, and the UK.",
            partnerWebsite: "https://www.lurnigo.com",
        },
        {
            partnerLogo: LocalImages?.SpanIdea,
            partnerName: "Spanidea Systems",
            partnerText: "Spanidea Systems is a global leader in technology design and system engineering at the forefront of innovation. It specialize in providing cutting-edge solutions and services to empower businesses in the digital era. With its deep expertise in system engineering and a keen understanding of emerging technologies, it enable organizations to optimize their operations, enhance efficiency, and achieve sustainable growth.",
            partnerWebsite: "https://www.spanidea.com",
        },
        {
            partnerLogo: LocalImages?.Matellio,
            partnerName: "Matellio Inc.",
            partnerText: "Matellio Inc. is a software engineering studio with a decade-long experience and knack for providing innovative and leading edge software solutions. Its clientele ranges from small startups to large enterprises and corporations across the globe.",
            partnerWebsite: "https://www.matellio.com",
        },
    ]

    return (
        <div>
            <Header />
            <div className="overflow-hidden position-relative">
                <div className="homeBanner">
                    <img src={LocalImages?.ourteam} className="bannerImg" />
                </div>
            </div>

            <div className="aow commonPad mt-3 mt-xl-5">
                <div className="text-center">
                    <div className="font48 fw-bold headingborder">OUR CORPORATE PARTNERS</div>
                </div>

                <div className="ourpartner mt-5">
                    <p>
                        Our Corporate Partners
                        At Rashmi Welfare Society, our mission is greatly strengthened by the invaluable partnerships we forge with corporate allies. These collaborations provide us with the vital support, resources and expertise needed to drive meaningful change in the communities we serve.
                    </p>
                    <p>
                        Our corporate partners help us extend our reach and engage new audiences, enabling us to create a more significant impact. We deeply appreciate the strong and enduring relationships we've cultivated with our partners, both in India and globally. These connections are rooted in shared values and common goals.
                    </p>
                    <p>
                        We wholeheartedly acknowledge and value their contributions. Without their unwavering support, our achievements wouldn't be possible.
                    </p>
                </div>
            </div>

            <div className="aow commonPad">
                <div className="row">
                    {PartnerList?.map((item, index) => (
                        <PartnerCard data={item} count={index} />
                    ))}
                </div>
            </div>

            <div className="aow commonPad">
                <div className="contactmail p-3 text-center">
                    <span className="text-secondary">
                        To know more, email us at
                    </span>
                    <br />
                    <span className="fs-5 fw-bold">
                        contact@rashmii.org
                    </span>
                </div>
            </div>

            <GetInvoled openPopup={() => setOpen(true)} />
            <ContactPopUP open={open} handleClose={handleClose} />
            <Footer />
        </div >
    );
}
