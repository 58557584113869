const LocalImages = {

    logo: require("../Assets/Images/Header/logo.png"),
    footerlogo: require("../Assets/Images/Home/footerlogo.png"),
    logoIcon: require("../Assets/Images/Home/logoIcon.png"),

    /*************************Home*********************************** */
    homeBannerImg1: require("../Assets/Images/Home/homeBannerImg1.png"),
    homeBannerImg2: require("../Assets/Images/Home/homeBannerImg2.png"),
    homeBannerImg3: require("../Assets/Images/Home/homeBannerImg3.png"),
    homeBannerImg4: require("../Assets/Images/Home/homeBannerImg4.png"),
    aowImg1: require("../Assets/Images/Home/aowImg1.png"),
    aowImg2: require("../Assets/Images/Home/aowImg2.png"),
    aowImg3: require("../Assets/Images/Home/aowImg3.png"),
    aowImg4: require("../Assets/Images/Home/aowImg4.png"),
    ladderIcon: require("../Assets/Images/Home/ladderIcon.png"),
    trophyIcon: require("../Assets/Images/Home/trophyIcon.png"),
    nanhiKaliIcon: require("../Assets/Images/Home/nanhiKaliIcon.png"),
    balanceScaleIcon: require("../Assets/Images/Home/balanceScaleIcon.png"),
    educationIcon: require("../Assets/Images/Home/educationIcon.png"),
    ourStoryIcon: require("../Assets/Images/Home/ourStoryIcon.png"),
    areaWorkIcon: require("../Assets/Images/Home/areaWorkIcon.png"),
    HeartSpeakIcon: require("../Assets/Images/Home/HeartSpeakIcon.png"),
    ourPartnersIcon: require("../Assets/Images/Home/ourPartnersIcon.png"),
    SpotlightIcon: require("../Assets/Images/Home/SpotlightIcon.png"),
    StoriesOfChangeIcon: require("../Assets/Images/Home/StoriesOfChangeIcon.png"),
    rashmi: require("../Assets/Images/Home/rashmi.png"),
    tayariKalKi: require("../Assets/Images/Home/tayariKalKi.png"),

    newsImg1: require("../Assets/Images/Home/newsImg1.png"),
    newsImg2: require("../Assets/Images/Home/newsImg2.png"),
    newsImg3: require("../Assets/Images/Home/newsImg3.png"),
    newsImg4: require("../Assets/Images/Home/newsImg4.png"),

    newsPaperIcon: require("../Assets/Images/Home/newsPaperIcon.png"),
    newsroomIcon: require("../Assets/Images/Home/newsroomIcon.png"),
    storyChangeBanner: require("../Assets/Images/Home/storyChangeBanner.png"),

    soc1: require("../Assets/Images/Home/soc1.png"),
    soc2: require("../Assets/Images/Home/soc2.png"),

    DhisterLogo: require("../Assets/Images/Home/DhisterLogo.png"),
    ifasLogo: require("../Assets/Images/Home/ifasLogo.png"),
    lurnigoLogo: require("../Assets/Images/Home/lurnigoLogo.png"),
    twoBrothersLogo: require("../Assets/Images/Home/twoBrothersLogo.png"),
    SpanIdea: require("../Assets/Images/Home/SpanIdea.png"),
    Matellio: require("../Assets/Images/Home/Matellio.png"),


    ourPeopleBanner1: require("../Assets/Images/Home/ourPeopleBanner1.png"),
    ourPeopleBanner2: require("../Assets/Images/Home/ourPeopleBanner2.png"),
    ourVoiceVideo: require("../Assets/Video/VideoRashmi.mp4"),
    doubleQoutes: require("../Assets/Images/Home/doubleQoutes.png"),

    getInTouch: require("../Assets/Images/Home/getInTouch.png"),
    becomeVolinter: require("../Assets/Images/Home/becomeVolinter.png"),
    donate: require("../Assets/Images/Home/donate.png"),
    headquarter: require('../Assets/Images/Home/HEADQUARTER.png'),
    california: require('../Assets/Images/Home/california.png'),
    pune: require('../Assets/Images/Home/pune.png'),
    donateIcon: require('../Assets/Images/Icon/donateIcon.png'),

    ClassroomTransform: require("../Assets/Video/ClassroomTransform.mp4"),


    GirlWithBook: require("../Assets/Video/VideoRashmi.mp4"),

    VideoCompLab: require("../Assets/Video/VideoCompLab.mp4"),


    // videoLogo:require("../Assets/Images/Home/videoLogo.png"),

    /*************************Contact*********************************** */
    puneofc: require('../Assets/Images/Contact/puneofc.png'),
    calforniaofc: require('../Assets/Images/Contact/calforniaofc.png'),
    jodhpurofc: require('../Assets/Images/Contact/jodhpurofc.png'),
    contactUsBannerImg1: require("../Assets/Images/Contact/contactUsBannerImg1.jpg"),
    contactUsBannerImg2: require("../Assets/Images/Contact/contactUsBannerImg2.jpg"),
    contactUsBannerImg3: require("../Assets/Images/Contact/contactUsBannerImg3.jpg"),
    contactUsBannerImg4: require("../Assets/Images/Contact/contactUsBannerImg4.jpg"),

    GetInTouch1: require("../Assets/Images/Contact/GetInTouch1.jpg"),
    GetInTouch2: require("../Assets/Images/Contact/GetInTouch2.jpg"),

    invitationCard: require('../Assets/Images/Invitation/invitationCard.png'),
    invitationBg: require('../Assets/Images/Invitation/invitationBg.jpg'),

    /*************************Volunteer*********************************** */

    volunteer: require("../Assets/Images/Volunteer/Volunteer.png"),

    productive: require("../Assets/Images/Volunteer/Productivity.jpg"),

    career: require("../Assets/Images/Volunteer/RecentActivities/Career.jpg"),
    food: require("../Assets/Images/Volunteer/RecentActivities/Food.jpg"),
    hostel: require("../Assets/Images/Volunteer/RecentActivities/Hostel.jpg"),
    labopen: require("../Assets/Images/Volunteer/RecentActivities/LabOpen.jpg"),

    digitallab: require("../Assets/Images/Volunteer/VolunteeringOpportunities/DigitalLab.jpg"),
    education: require("../Assets/Images/Volunteer/VolunteeringOpportunities/Education.jpg"),
    meal: require("../Assets/Images/Volunteer/VolunteeringOpportunities/Meal.jpg"),
    health: require("../Assets/Images/Volunteer/VolunteeringOpportunities/Health.jpg"),

    slide1: require("../Assets/Images/Volunteer/slide1.jpg"),
    slide2: require("../Assets/Images/Volunteer/slide2.jpg"),
    slide3: require("../Assets/Images/Volunteer/slide3.jpg"),
    slide4: require("../Assets/Images/Volunteer/slide4.jpg"),
    videoicon: require("../Assets/Images/Volunteer/videoicon.png"),

    /****************************Our Team******************************** */
    ourteam: require("../Assets/Images/OurTeam/Team.jpg"),

    kcsir: require("../Assets/Images/OurTeam/KCSir.jpg"),
    rcsir: require("../Assets/Images/OurTeam/RCSir.jpg"),

    romillamam: require("../Assets/Images/OurTeam/RomilaTutoo.jpg"),
    vikendrasir: require("../Assets/Images/OurTeam/VikendraMetha.jpg"),
    sondgesir: require("../Assets/Images/OurTeam/DadasahebSondge.jpg"),
    dvsir: require("../Assets/Images/OurTeam/DharmveerSharma.jpg"),
    gitamam: require("../Assets/Images/OurTeam/GitanjaleeJadhav.jpg"),
    laljisir: require("../Assets/Images/OurTeam/LaljiKannoujiya.jpg"),
    kalesir: require("../Assets/Images/OurTeam/ParmeshwarKale.jpg"),
    rajeshsir: require("../Assets/Images/OurTeam/RajeshMandal.jpg"),
    djsir: require("../Assets/Images/OurTeam/DigambarJagtap.jpg"),
    yogeshsir: require("../Assets/Images/OurTeam/YogeshRaut.jpg"),
    // kshama:require("../Assets/Images/OurTeam/kshamaPatil.jpg"),
    // sanju:require("../Assets/Images/OurTeam/sanjuSherwade.jpg"),
    // siddhart:require("../Assets/Images/OurTeam/siddhartKhubikar.jpg"),
    // supriya:require("../Assets/Images/OurTeam/supriyaSutar.jpg"),
    // divya:require("../Assets/Images/OurTeam/divyaGhare.jpg"),

    /****************************Partner******************************** */


}


export default LocalImages;