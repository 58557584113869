class AuthReducerModal {
  loginFor = "";
  NotificationCount = 0;
  NotificationID = 0;
}

class SnackbarReducerModal {
  successSnackbarOpen = false;
  errorSnackbarOpen = false;
  infoSnackbarOpen = false;
  message = "";
}
export { AuthReducerModal, SnackbarReducerModal };
