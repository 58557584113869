import React, { useState } from 'react';
import LocalImages from "../Constant/localImages";

const YOUTUBE_VIDEO_ID = '8qZv9DiQ0Ac';
const YOUTUBE_THUMBNAIL_URL = `https://img.youtube.com/vi/${YOUTUBE_VIDEO_ID}/maxresdefault.jpg`;


const YTThumbnailGirlBook: React.FC = () => {
    const [isPlaying, setIsPlaying] = useState(false);

    const handlePlay = () => {
        setIsPlaying(true);
    };

    return (
        <div className="col-md-12 col-xl-6 d-flex justify-content-center">
            <div className="girlBook">
                {!isPlaying ? (
                    <>
                        <img
                            src={YOUTUBE_THUMBNAIL_URL}
                            alt="YouTube Thumbnail"
                            className="thumbnail"
                            onClick={handlePlay}
                        />
                        <img
                            src={LocalImages?.videoicon}
                            alt="Play Icon"
                            className="playVideoIcon"
                            onClick={handlePlay}
                        />
                    </>
                ) : (
                    <video muted autoPlay controls>
                        <source src={LocalImages.VideoCompLab} type="video/mp4" />
                    </video>
                )}

            </div>
        </div>
    );
};
    export default YTThumbnailGirlBook;