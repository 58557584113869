import React, { useState } from "react";
import Header from "../Component/header";
import HomeBannerSlider from "../Component/homeBannerSlider";
import "../Assets/CSS/home.css";
import "../Assets/CSS/ourteam.css";
import LocalImages from "../Constant/localImages";
import AowCard from "../Component/aowCard";
import PartnerCompanies from "../Component/partnerCompanies";
import OurVoiceSwiper from "../Component/ourVoiceSwiper";
import ContactPopUP from "../Component/contactPopup";
import Footer from "../Component/footer";
import GetInvoled from "../Component/getInvoled";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination } from "swiper/modules";
import { useNavigate } from "react-router-dom";
import ExpandMoreSharpIcon from '@mui/icons-material/ExpandMoreSharp';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import TeamCard from "../Component/teamCard";
export default function OurTeam() {
    const [open, setOpen] = useState<boolean>(false);
    const [selectedFounder, setselectedFounder] = useState<string>('');
    const [selectedTeam, setselectedTeam] = useState<string>('');
    const navigate = useNavigate();
    const handleClose = () => {
        setOpen(false);
    };

    const cofounder = [
        {
            _founderID: "1",
            founderImage: LocalImages?.rcsir,
            founderName: "Radheshyam Choudhary"
        },
        {
            _founderID: "1",
            founderName: "Radheshyam Choudhary",
            founderPosition: "Co-Founder - Rashmi Welfare Society",
            founderBlog: "Radheshyam Choudhary, a serial entrepreneur and co-founder of IFAS Edutech, Dhister, Lurnigo, and Two Brothers Publication, drives innovation and success across various ventures. As Co-Founder of Rashmi Welfare Society, he is dedicated to giving back to society by focusing on rural empowerment and girls' education. Radheshyam’s vision and commitment are pivotal in advancing the NGO’s mission and making a meaningful impact on young girls' lives."
        },

        {
            _founderID: "2",
            founderImage: LocalImages?.kcsir,
            founderName: "Dr. Kailash Choudhary"
        },
        {
            _founderID: "2",
            founderName: "Dr. Kailash Choudhary",
            founderPosition: "Co-Founder - Rashmi Welfare Society",
            founderBlog: "Dr. Kailash Choudhary, a serial entrepreneur and co-founder of IFAS Edutech, Dhister, Lurnigo, and Two Brothers Publication, is a leading educator in India. Renowned for his expertise in graduate and post-graduate education, he has authored over 50 books. As Co-Founder of Rashmi Welfare Society, Dr. Choudhary is dedicated to advancing girls' education and empowerment, leveraging his extensive experience and passion to drive the NGO’s mission forward."
        },

    ]
    const teamChart = [
        {
            _teamID: "1",
            teamPhoto: LocalImages?.sondgesir,
            teamName: "Dadasaheb Sondge",
        },
        {
            _teamID: "1",
            teamPosition: "Chief Executive Office (CEO)",
            teamBlog: "Dadasaheb Sondge, Chief Executive Office (CEO) of Rashmi Welfare Society, leads the NGO with a focus on advancing girls' education and empowerment. His strategic vision and leadership drive the organization’s mission, ensuring impactful educational programs and initiatives. Dadasaheb’s commitment enhances the NGO’s effectiveness, significantly improving the lives of young girls and expanding its reach and impact.",
        },
        {
            _teamID: "2",
            teamPhoto: LocalImages?.djsir,
            teamName: "Digambar Jagtap",
        },
        {
            _teamID: "2",
            teamPosition: "Chief Operating Officer (COO)",
            teamBlog: "Digambar Jagtap, Chief Operating Officer (COO) at Rashmi Welfare Society, excels in driving efficiency and results. His commitment to optimizing processes and ensuring smooth operations is key to the NGO’s growth and success. Under his leadership, the society has strengthened its impact and fulfilled its mission with dedication. Digambar’s strategic vision and operational skills continue to advance the organization’s goals and create lasting positive change.",
        },
        {
            _teamID: "3",
            teamPhoto: LocalImages?.romillamam,
            teamName: "Romila Tutoo",
        },
        {
            _teamID: "3",
            teamPosition: "Chief Marketing Officer (CMO)",
            teamBlog: "Romila Tutoo, Chief Marketing Officer (CMO) at Rashmi Welfare Society, drives the NGO’s outreach and engagement efforts with her strategic communications expertise. Committed to social change, Romila's innovative marketing approach amplifies young women’s voices and supports impactful programs. Her leadership advances gender equality and provides crucial opportunities for girls to thrive, making a significant difference in their lives.",
        },
        {
            _teamID: "4",
            teamPhoto: LocalImages?.kalesir,
            teamName: "Parmeshwar Kale",
        },
        {
            _teamID: "4",
            teamPosition: "Chief Financial Officer (CFO)",
            teamBlog: "Parmeshwar Kale, Chief Financial Officer (CFO) at Rashmi Welfare Society, ensures financial health and transparency. With strong expertise in financial management, he oversees budgeting, reporting, and resource allocation to support the NGO’s mission of empowering girls. Parmeshwar’s financial acumen maximizes the impact of contributions and drives efficiency, playing a key role in advancing the NGO’s goals and fostering positive change for young women.",
        },
        {
            _teamID: "5",
            teamPhoto: LocalImages?.dvsir,
            teamName: "Dharmveer Sharma",

        },
        {
            _teamID: "5",
            teamPosition: "Chief Technology Officer (CTO)",
            teamBlog: "Dharmveer Sharma, Chief Technology Officer (CTO) at Rashmi Welfare Society, drives technological innovation and digital transformation. As the visionary behind the NGO’s AI-powered website, he has created a cutting-edge platform that aims to make Rashmi Welfare Society India’s leading NGO for girls' empowerment. Dharmveer’s tech expertise enhances organizational efficiency and provides a seamless digital experience, advancing the NGO’s mission with impact.",
        },
        {
            _teamID: "6",
            teamPhoto: LocalImages?.gitamam,
            teamName: "Gitanjalee Jadhav",
        },
        {
            _teamID: "6",
            teamPosition: "Chief Human Resources Officer (CHRO)",
            teamBlog: "Gitanjalee Jadhav, Chief Human Resources Officer (CHRO) at Rashmi Welfare Society, is essential in managing and nurturing the organization's people. She oversees recruitment, talent development, and employee engagement, ensuring alignment with the NGO’s mission. Gitanjalee’s commitment to a supportive work environment and her HR expertise drive the successful empowerment of girls and support the NGO’s growth.",
        },
        {
            _teamID: "7",
            teamPhoto: LocalImages?.laljisir,
            teamName: "Lalji Kannoujiya",
        },
        {
            _teamID: "7",
            teamPosition: "Chief Growth Officer (CGO)",
            teamBlog: "Lalji Kannoujiya, Chief Growth Officer (CGO) at Rashmi Welfare Society, drives the NGO’s expansion and development. Focused on growth strategies and partnerships, Lalji enhances initiatives for girls' education and empowerment. His innovative approach to scaling programs and building collaborations significantly boosts the organization’s impact, advancing its mission and supporting young girls more effectively.",
        },
        {
            _teamID: "8",
            teamPhoto: LocalImages?.vikendrasir,
            teamName: "Vikendra Metha",
        },
        {
            _teamID: "8",
            teamPosition: "Chief Business Officer (CBO)",
            teamBlog: "Vikendra Mehta, Chief Business Officer (CBO) at Rashmi Welfare Society, leads strategic business initiatives to support girls' education and empowerment. With a focus on business development and efficiency, Vikendra manages sustainable models and partnerships, enhancing the NGO’s impact. His leadership ensures effective resource use and growth, helping Rashmi Welfare Society achieve its goals and expand its support for young girls.",
        },
        {
            _teamID: "9",
            teamPhoto: LocalImages?.rajeshsir,
            teamName: "Rajesh Mandal",
        },
        {
            _teamID: "9",
            teamPosition: "Chief Resource Officer (CRO)",
            teamBlog: "Rajesh Mondal, Chief Resource Officer at Rashmi Welfare Society, manages and optimizes resources crucial for girls' education and empowerment. His expertise in resource allocation and fundraising ensures the NGO maximizes impact and efficiency. Rajesh’s strategic approach sustains and expands programs, driving significant support and advancing the organization’s mission to benefit young girls.",
        },
        {
            _teamID: "9",
            teamPhoto: LocalImages?.yogeshsir,
            teamName: "Yogesh Raut",
        },
        {
            _teamID: "10",
            teamPosition: "Chief Resource Officer (CRO)",
            teamBlog: "Rajesh Mondal, Chief Resource Officer at Rashmi Welfare Society, manages and optimizes resources crucial for girls' education and empowerment. His expertise in resource allocation and fundraising ensures the NGO maximizes impact and efficiency. Rajesh’s strategic approach sustains and expands programs, driving significant support and advancing the organization’s mission to benefit young girls.",
        },
    ];

    const gotoLink = (val: string) => {
        navigate("/" + val)
    }

    return (
        <div>
            <Header />
            <div className="overflow-hidden position-relative">
                <div className="homeBanner">
                    <img src={LocalImages?.ourteam} className="bannerImg" />
                </div>
                <div className="teamphotoOverlay">
                    <div className="font48 text-center pb-3 ourteamHeading">
                        OUR TEAM
                    </div>
                    <div className="font18 text-center my-3">
                        Our Rashmii team is passionately dedicated to empowering girls and creating opportunities for their growth. With a shared vision of gender equality and education, we work tirelessly to provide support, mentorship, and resources that help girls thrive. Our diverse team combines compassion with expertise, working collaboratively to break down barriers and uplift young women. Together, we are committed to fostering a brighter future where every girl has the chance to achieve her fullest potential.
                    </div>
                </div>
            </div>

            <div className="aow commonPad mt-3 mt-xl-5">
                <div className="text-center">
                    <div className="font48 fw-bold my-2 headingborder">CO-FOUNDER'S</div>
                </div>
                <div className="row mx-5 d-block mt-3">
                    <div className="col-md-12 col-xl-6 mb-3 mt-3 mt-md-4 d-inline-block float-none">
                        <div className="d-flex justify-content-center founderactivityCard">
                            <div className="col-12 shadow-sm  rounded-3 overflow-hidden">
                                <img src={LocalImages?.rcsir} alt="sir" className='founderImage' />
                                <div className='font18 founderText mx-3'>
                                    <div className="col-md-11 text-center">
                                        Radheshyam Choudhary
                                    </div>
                                    <div className="col-md-1">
                                        {selectedFounder === 'rcSir' ?
                                            <CloseSharpIcon fontSize="large" onClick={() => setselectedFounder('')} /> :
                                            <ExpandMoreSharpIcon className="cursor" fontSize="large" onClick={() => setselectedFounder('rcSir')} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`float-start p-4 w-100 founderteamBorder textTransition ${selectedFounder === 'rcSir' ? 'active' : ''}`} id="collapse2">
                        <h2 className="py-2">
                            Co-Founder : Rashmi Welfare Society
                        </h2>
                        <p className="blogText font18 py-2">
                        Radheshyam Choudhary, a serial entrepreneur and co-founder of IFAS Edutech, Dhister, Lurnigo, and Two Brothers Publication, drives innovation and success across various ventures. As Co-Founder of Rashmi Welfare Society, he is dedicated to giving back to society by focusing on rural empowerment and girls' education. Radheshyam’s vision and commitment are pivotal in advancing the NGO’s mission and making a meaningful impact on young girls' lives.
                        </p>
                    </div>

                    <div className="col-md-12 col-xl-6 mb-3 mt-3 mt-md-4 d-inline-block float-none">
                        <div className="d-flex justify-content-center founderactivityCard">
                            <div className="col-12 shadow-sm rounded-3 overflow-hidden">
                                <img src={LocalImages?.kcsir} alt="sir" className='founderImage' />
                                <div className='font18 founderText mx-3'>
                                    <div className="col-md-11 text-center">
                                        Dr. Kailash Choudhary
                                    </div>
                                    <div className="col-md-1">
                                        {selectedFounder === 'kcSir' ?
                                            <CloseSharpIcon fontSize="large" onClick={() => setselectedFounder('')} /> :
                                            <ExpandMoreSharpIcon className="cursor" fontSize="large" onClick={() => setselectedFounder('kcSir')} />
                                        }


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`float-start w-100 founderteamBorder textTransition ${selectedFounder === 'kcSir' ? 'active' : ''}`} id="collapse1">
                        <h2 className="py-2">
                            Co-Founder : Rashmi Welfare Society
                        </h2>
                        <p className="blogText font18 py-2">
                        Dr. Kailash Choudhary a serial entrepreneur and co-founder of IFAS Edutech, Dhister, Lurnigo, and Two Brothers Publication, is a leading educator in India. Renowned for his expertise in graduate and post-graduate education, he has authored over 50 books. As Co-Founder of Rashmi Welfare Society, Dr. Choudhary is dedicated to advancing girls' education and empowerment, leveraging his extensive experience and passion to drive the NGO’s mission forward.
                        </p>

                    </div>

                </div>
            </div>

            <div className="ourStory commonPad mb-4">
                <div className="text-center">
                    <div className="font48 fw-bold d-inline-block headingborder">
                        RASHMII TEAM
                    </div>
                </div>
                <div className="mt-2">
                    <div className="row d-block">
                        {teamChart?.map((item, index) => (
                            index % 2 === 0 ?
                                <div className="col-md-6 col-xl-3 mt-0 mt-md-5 d-inline-block float-none">
                                    <div className="d-flex justify-content-center">

                                        <TeamCard data={item} count={index} handleClick={setselectedTeam} selectedTeam={selectedTeam} />

                                    </div>
                                </div> :
                                <div className={` mt-3 px-4 float-start w-100 founderteamBorder textTransition  ${selectedTeam === item?._teamID ? 'active' : ''}`}>
                                    <h2 className="py-2">
                                        {item?.teamPosition}
                                    </h2>
                                    <p className="blogText font18 py-2
                                    ">
                                        {item?.teamBlog}
                                    </p>
                                </div>



                        ))}
                    </div>
                </div>
            </div>

            <GetInvoled openPopup={() => setOpen(true)} />
            <ContactPopUP open={open} handleClose={handleClose} />
            <Footer />
        </div >
    );
}
