import React, { useState } from "react";
import "../Assets/CSS/home.css";
import "../Assets/CSS/ourteam.css";
import ExpandMoreSharpIcon from '@mui/icons-material/ExpandMoreSharp';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';




export default function TeamCard(props: any) {


    return (

        <div className="col-12 shadow-sm teamactivityCard">
            <img src={props?.data?.teamPhoto} alt={props?.data?.teamName} className='teamImage' />
            <div className='font18 teamText mx-3 row'>
                <div className="col-md-10 text-center">
                    {props?.data?.teamName}
                </div>
                <div className="col-md-2">
                    {props?.selectedTeam === props?.data?._teamID ?
                        <CloseSharpIcon   fontSize="large" onClick={() => props?.handleClick('')} /> :
                        <ExpandMoreSharpIcon fontSize="large" onClick={() => props?.handleClick(props?.data?._teamID)} className="cursor" />
                    }
                </div>
            </div>


            {/* <div className="collapse mt-3 px-3" id={props?.data?._teamID}>
                <p className="font18">
                    {props?.data?.teamPosition}
                </p>
                <p className="blogText">
                    {props?.data?.teamBlog}
                </p>
            </div> */}
        </div>

    );
}

