import { combineReducers } from 'redux';
import AuthReducer from './Reducers/authReducer';
import uiReducer from './Reducers/uiReducer';
import { RESET_STORE } from '../Types/authTypes';

const appReducer = combineReducers({
  AuthReducer,
  uiReducer,
});

const rootReducer = (state:any, action:any) => {
  if (action.type === RESET_STORE) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
